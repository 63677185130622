import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TagOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  DashboardOutlined,
  AlipayOutlined,
  SplitCellsOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { deleteCookie } from '../axios/functions/useCookies';
import { TOKEN } from '../axios/constants';
import { logoutUser } from '../axios/server/config/User';

const { Header, Sider, Content } = Layout;

const LayoutComponent = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logOut = () => {
    logoutUser();
    deleteCookie(TOKEN);
    window.location.href = '/login';
  };

  return (
    <Layout className='admin-panel'>
      <Sider className='left_leyout' trigger={null} collapsible collapsed={collapsed}>
        <div className='logo'> </div>
        <Menu theme='dark' mode='inline' defaultSelectedKeys={['1']}>
          <Menu.Item key='/1' icon={<DashboardOutlined />}>
            <Link to='/'>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key='6' icon={<UsergroupAddOutlined />}>
            <Link to='/client'>Clients</Link>
          </Menu.Item>
          <Menu.Item key='2' icon={<TagOutlined />}>
            <Link to='/service'>Service</Link>
          </Menu.Item>
          <Menu.Item key='3' icon={<UserOutlined />}>
            <Link to='/doctors'>Doctors</Link>
          </Menu.Item>
          <Menu.Item key='4' icon={<ToolOutlined />}>
            <Link to='/tool'>Tools</Link>
          </Menu.Item>
          <Menu.Item key='7' icon={<SplitCellsOutlined />}>
            <Link to='/packetCategory'>Packet Category</Link>
          </Menu.Item>
          <Menu.Item key='8' icon={<AlipayOutlined />}>
            <Link to='/packet'>Packets</Link>
          </Menu.Item>
          <Menu.Item key='9' icon={<InfoCircleOutlined />}>
            <Link to='/info'>Clinic Info</Link>
          </Menu.Item>
          <Menu.Item onClick={logOut} key='10' icon={<LogoutOutlined />}>
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className='site-layout right_layout'>
        <Header className='site-layout-background layour_header' style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
          <h1
            style={{
              display: 'inline-block',
              color: 'white',
              marginLeft: 30,
              fontSize: 20,
            }}
          >
            Reception Admin Panel
          </h1>
        </Header>
        <Content
          className='site-layout-background main_responsive_content'
          style={{
            margin: '10px',
            padding: '1rem 1rem 0',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
