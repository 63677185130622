import React, { useEffect, useState } from 'react';
import './Client.css';
import { Button, Input, Table, Select, Space } from 'antd';
import { ConfirmUser, OrderGetByStatus } from '../../axios/server/config/ClientCrud';
import { resHandler } from '../../helper/response-handler';

const { Option } = Select;

const statutes = [
  {
    name: 'BARCHASI',
    value: '',
  },
  {
    name: 'YARATILDI',
    value: 'created',
  },
  {
    name: "TO'LANDI",
    value: 'active',
  },
  {
    name: 'DOCTOR QABUL QILDI',
    value: 'progress',
  },
  {
    name: 'BEKOR QILINDI',
    value: 'canceled',
  },
  {
    name: "XIZMAT KO'RSATILIB BO'LINDI",
    value: 'completed',
  },
];

const Client = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    getClients();
  }, []);

  const getClients = (status) => {
    resHandler({
      promise: OrderGetByStatus,
      props: status,
      loader: setLoading,
      onSuccess: setUserList,
    });
  };

  const columns = [
    {
      title: 'Mijoz',
      dataIndex: 'user',
      render: ({ name, surname }) => (
        <span>
          {surname} {name}
        </span>
      ),
    },
    {
      title: 'Servis',
      dataIndex: 'service',
      render: ({ name }) => <span>{name}</span>,
    },
    {
      title: 'Shifokor',
      dataIndex: 'doctor',
      render: ({ user }) => <span>{user.fio}</span>,
    },
    {
      title: 'Kod orqali tasdiqlash',
      dataIndex: 'id',
      render: (order_id) => (
        <Space>
          <Input
            onChange={({ target: { value } }) => {
              setCode(value);
            }}
            placeholder='kodni kiriting'
          />
          <Button
            type='primary'
            htmlType='submit'
            onClick={() => {
              confirm({ code, order_id });
            }}
          >
            Tasdiqlash
          </Button>
        </Space>
      ),
    },
  ];

  const confirm = (props) => {
    resHandler({
      promise: ConfirmUser,
      props,
      loader: setLoading,
      onSuccess: () => getClients(userStatus),
      message: true,
    });
  };

  const statusChange = (val) => {
    setUserStatus(val);
    getClients(val);
  };

  return (
    <div>
      <Table
        title={() => (
          <Space>
            <b>Userlarni status bo'yicha olish</b>
            <Select value={userStatus} onChange={statusChange}>
              {statutes.map((status) => (
                <Option value={status.value} key={status.value}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Space>
        )}
        rowKey='id'
        loading={loading}
        dataSource={userList}
        columns={columns}
      />
    </div>
  );
};

export default Client;
