import { HttpRequestHub } from "../HttpRequestHub";

export const GetAllDoctors = (search) => {
  const query = search ? `?search=${search}` : "";
  const config = {
    method: "GET",
    url: "reception/doctor/get-list" + query,
  };
  return HttpRequestHub(config);
};

export const SendDoctor = (data) => {
  const config = {
    method: "POST",
    url: "reception/doctor/store",
    data,
  };
  return HttpRequestHub(config);
};

export const EditDoctorConfig = ({ id, data }) => {
  const config = {
    method: "POST",
    url: "reception/doctor/update/" + id,
    data,
  };
  return HttpRequestHub(config);
};

export const GetDoctorsByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: "reception/doctor/show/" + id,
  };
  return HttpRequestHub(config);
};

export const DoctorTimeTableConfig = (data) => {
  const config = {
    method: "POST",
    url: "reception/doctor/time-table/store",
    data,
  };
  return HttpRequestHub(config);
};

export const getDoctorTimeTableByDoctorId = (id) => {
  const config = {
    method: "GET",
    url: "reception/doctor/time-table/show/" + id,
  };
  return HttpRequestHub(config);
};

export const EditDocTimeTable = ({ id, data }) => {
  const config = {
    method: "POST",
    url: "reception/doctor/time-table/update/" + id,
    data,
  };
  return HttpRequestHub(config);
};

export const getTimeTableWeekDays = () => {
  const config = {
    method: "GET",
    url: "reception/doctor/time-table/weekdays",
  };
  return HttpRequestHub(config);
};

export const getInfoSpecialities = () => {
  const config = {
    method: "GET",
    url: "reception/doctor/information-list/specialties",
  };
  return HttpRequestHub(config);
};

export const getInfoAcademicTitles = () => {
  const config = {
    method: "GET",
    url: "reception/doctor/information-list/academic-titles",
  };
  return HttpRequestHub(config);
};

export const getInfoLanguages = () => {
  const config = {
    method: "GET",
    url: "reception/doctor/information-list/languages",
  };
  return HttpRequestHub(config);
};

export const getInfoSpecializations = () => {
  const config = {
    method: "GET",
    url: "reception/doctor/information-list/specializations",
  };
  return HttpRequestHub(config);
};

export const getInfoSpecializationDirections = (specialization_id) => {
  const config = {
    method: "GET",
    url:
      "reception/doctor/information-list/specialization-directions?specialization_id=" +
      specialization_id,
  };
  return HttpRequestHub(config);
};
