import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Card, Table, Statistic, Space } from "antd";
import {
  getActiveOrderList,
  OrderResponseSend,
  OrdersGet,
} from "../../axios/server/config/OrderCrud";
// import SockJS from 'sockjs-client';
// import { WEB_SOCKET_URL } from '../../axios/constants';
import moment from "moment";
import { resHandler } from "../../helper/response-handler";

// const Stomp = require('stompjs');
const { Countdown } = Statistic;
const gridStyle = {
  width: "25%",
  textAlign: "center",
};

const Dashboard = () => {
  const [orderTimes, setOrderTimes] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);

  // const [time, setTime] = useState(60000);
  // const [serviceScheduleObj, setServiceSchedule] = useState({});
  // const [isSchedule, setSchedule] = useState(false);
  // const [scheduleId, setScheduleId] = useState('');
  // const [serviceName, setServiceName] = useState('');
  // const [clinicId, setClinicId] = useState('');
  // const [doctorId, setDoctorId] = useState('');
  // const [categoryList, setCategoryList] = useState([]);
  // const [subCategoryList, setSubCategoryList] = useState([]);
  // const [spinner, setSpinner] = useState(false);

  // const getOrders = useCallback(() => {
  //   OrdersGet()
  //     .then((res) => {
  //       const Data = res.data;
  //       setOrderList(Data);
  //
  //       Data.forEach((item) => {
  //         if (moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() === 240000) {
  //           getOrders();
  //         }
  //       });
  //       let a = 60000;
  //       let b = 59000;
  //       Data.forEach((item) => {
  //         if (moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() > 240000) {
  //           if (moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() - 240000 < a) {
  //             a = moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() - 240000;
  //           }
  //         }
  //         if (
  //           moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() < 240000 &&
  //           moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() > 210000
  //         ) {
  //           if (moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() - 210000 < 30000) {
  //             b = moment(item.createdTime).valueOf() + 5 * 60 * 1000 - Date.now() - 210000;
  //           }
  //         }
  //       });
  //
  //       if (a < b) setTime(a);
  //       // else if (a = b) setTime(a);
  //       else if (b < a) setTime(b);
  //     })
  //     .catch(() => {
  //       messageErr();
  //     });
  // }, []);

  const getOrders = () => {
    resHandler({
      promise: OrdersGet,
      onSuccess: (data) => {
        // data.dates = data.dates.map((date) => {
        //   const passedMilliSeconds = new Date().getTime() - new Date(date.created_at).getTime();
        //   const leftTime = 5 * 60 * 1000 - passedMilliSeconds;
        //   return { ...date, leftTime };
        // });
        setOrderList(data);
      },
    });
  };

  const chooseOrder = (item) => {
    setOrderTimes(item);
  };

  // const getTimeTable = (id) => {
  //   OrderGetById(id)
  //     .then((res) => {
  //       const Data = res.data;
  //       setSchedule(false);
  //       let newObj = {};
  //       let newschedule = [];
  //       Data.serviceSchedule.forEach((res) => {
  //         let newday = {};
  //         let newtimes = {};
  //         Data.orderSchedule.forEach((item) => {
  //           if (item.weekdays === res.weekdays) {
  //             Object.keys(res.times).forEach((serkey) => {
  //               if (Object.keys(item.times).includes(serkey)) {
  //                 newtimes[serkey] = item.times[serkey];
  //               } else {
  //                 if (res.times[serkey] === 'RESERVED') {
  //                   newtimes[serkey] = 'DISMISSED';
  //                 } else if (res.times[serkey] === 'APPROVED') {
  //                   newtimes[serkey] = 'ADDED';
  //                 } else {
  //                   newtimes[serkey] = res.times[serkey];
  //                 }
  //               }
  //             });
  //             newday = {
  //               ...item,
  //               times: newtimes,
  //             };
  //             newschedule.push(newday);
  //           }
  //         });
  //       });
  //       newObj = {
  //         ...Data,
  //         orderSchedule: newschedule,
  //       };
  //       setOrderTimes(newObj);
  //     })
  //     .catch((err) => {
  //       messageErr();
  //     });
  // };

  const sendOrderResponse = () => {
    const formData = new FormData();
    orderTimes.dates.forEach((date, i) => {
      if (selectedTimes.includes(date.selected_date)) {
        formData.append(`dates[${i}][id]`, date.id);
        formData.append(`dates[${i}][check]`, "1");
      } else {
        formData.append(`dates[${i}][id]`, date.id);
        formData.append(`dates[${i}][check]`, "0");
      }
    });
    resHandler({
      promise: OrderResponseSend,
      props: { id: orderTimes.id, data: formData },
      onSuccess: () => {
        setOrderTimes(null);
        getOrders();
      },
      message: true,
    });
  };

  const getAllServices = () => {
    resHandler({
      promise: getActiveOrderList,
      loader: setLoading,
      onSuccess: setServiceList,
    });
  };

  const onTimeFinish = () => {
    getAllServices();
  };

  const changeStatus = (date) => {
    const { selected_date } = date;
    if (selectedTimes.includes(selected_date)) {
      setSelectedTimes(selectedTimes.filter((v) => v !== selected_date));
    } else {
      setSelectedTimes([...selectedTimes, selected_date]);
    }
  };

  // const getAllServices = useCallback(
  //   (id, page) => {
  //     setLoading(true);
  //     GetServiceItemByClinicId(clinicId ? clinicId : id, page ? page : 1)
  //       .then((res) => {
  //         setLoading(false);
  //         const Data = res.data.content;
  //         setDoctorId(Data[0].doctorId);
  //         getServiceScheduleById(Data[0].serviceItemId, Data[0].name, Data[0].doctorName);
  //         let keyed = Data.map((item) => {
  //           return { ...item, key: item.serviceItemId };
  //         });
  //         setServiceList(keyed);
  //       })
  //       .catch((err) => {
  //         messageErr();
  //       });
  //   },
  //   [clinicId]
  // );

  // const [doctorName, setDoctorName] = useState('');
  // const [isSpin, setSpin] = useState(false);
  // const getServiceScheduleById = (id, name, doctor) => {
  //   setDoctorName(doctor);
  //   if (name) {
  //     setServiceName(name);
  //     setSpin(true);
  //   }
  //   setScheduleId(id);
  //   GetServiceScheduleById(id)
  //     .then((res) => {
  //       setServiceSchedule(res.data);
  //       setSchedule(true);
  //       if (name) setSpin(false);
  //     })
  //     .catch((err) => {
  //       if (name) setSpin(false);
  //     });
  // };

  // const changeScheduleStatus = (scheduleStatusId, time, status) => {
  //   let arr = serviceScheduleObj.serviceSchedule;
  //   let newSchedule = [];
  //   arr.forEach((item) => {
  //     let newObj = {};
  //     let newtimes = {};
  //     if (item.timetableObjectId === scheduleStatusId) {
  //       Object.keys(item.times).forEach((res) => {
  //         if (res === time) {
  //           if (status === 'EMPTY') {
  //             newtimes[res] = 'BUSY';
  //           } else if (status === 'BUSY') {
  //             newtimes[res] = 'EMPTY';
  //           }
  //         }
  //       });
  //       newObj = { ...item, times: newtimes };
  //       if (Object.keys(newtimes).length > 0) {
  //         newSchedule.push(newObj);
  //       }
  //     }
  //   });
  //   EditServiceSchedule({
  //     ...serviceScheduleObj,
  //     serviceSchedule: newSchedule,
  //   })
  //     .then((res) => {
  //       getServiceScheduleById(scheduleId);
  //     })
  //     .catch((err) => {
  //       messageErr();
  //     });
  // };

  const columns = [
    {
      title: "Servis nomi",
      dataIndex: "service",
      render: ({ name }) => <span>{name}</span>,
    },
    {
      title: "Shifokor",
      dataIndex: "doctor",
      render: ({ user: { fio } }) => <span>{fio}</span>,
    },
    {
      title: "Servis vaqti",
      dataIndex: "service_time",
      render: (item) => <span>{item.slice(0, 16)}</span>,
    },
  ];

  // const getCategory = () => {
  //   resHandler({
  //     promise: getServiceCategoryList,
  //     onSuccess: setCategoryList,
  //     loader: setSpinner,
  //   });
  // };
  //
  // const changeCategory = (id) => {
  //   resHandler({
  //     promise: getServiceCategoryList,
  //     props: id,
  //     loader: setSpinner,
  //     onSuccess: setSubCategoryList,
  //   });
  // };
  //
  // const changeSubCategory = (id) => {
  //   resHandler({
  //     promise: getServicesBySubCategory,
  //     props: id,
  //     loader: setLoading,
  //     onSuccess: setServiceList,
  //   });
  // };

  // const reset = () => {
  //   // getAllServices();
  // };

  // const webSocket = useCallback(() => {
  //   if (!doctorId && clinicId) {
  //     const soc = new SockJS(WEB_SOCKET_URL);
  //     const stompClient = Stomp.over(soc);
  //     stompClient.connect({}, function () {
  //       stompClient.subscribe('/topic/reception', function (msg) {
  //         if (JSON.parse(msg.body).event === 'CLINICS' && JSON.parse(msg.body).content === clinicId) {
  //           getOrders();
  //           if (!getOrders) {
  //             stompClient.disconnect();
  //           }
  //         }
  //       });
  //     });
  //   }
  // }, [getOrders, clinicId, doctorId]);

  // const servicewebsocket = useCallback(() => {
  //   if (!clinicId && doctorId) {
  //     const soc = new SockJS(WEB_SOCKET_URL);
  //     const stompClient1 = Stomp.over(soc);
  //     stompClient1.connect({}, function () {
  //       stompClient1.subscribe('/topic/schedule', function (msg2) {
  //         if (JSON.parse(msg2.body).event === 'SERVICE_ITEM' && JSON.parse(msg2.body).content === doctorId) {
  //           getServiceScheduleById(scheduleId);
  //           if (!getServiceScheduleById(scheduleId)) {
  //             stompClient1.disconnect();
  //           }
  //         }
  //       });
  //     });
  //   }
  // }, [scheduleId, doctorId, clinicId]);

  // const getClinic = useCallback(() => {
  //   ClinicGet()
  //     .then((res) => {
  //       localStorage.setItem('clinicId', res.data[0].clinicsId);
  //       setClinicId(res.data[0].clinicsId);
  //       getAllServices(res.data[0].clinicsId);
  //     })
  //     .catch((err) => {
  //       messageErr();
  //     });
  // }, []);

  useEffect(() => {
    getAllServices();
    getOrders();
  }, []);

  // useEffect(() => {
  //   getClinic();
  //   setTimeout(() => {
  //     getOrders();
  //   }, time);
  // }, [time, getOrders]);
  //
  // useEffect(() => {
  //   webSocket();
  //   servicewebsocket();
  // }, [webSocket, servicewebsocket]);

  return (
    <div>
      <div className="dashboard-section">
        <div className="dashboard-left">
          <div className="dashboard-content">
            <div className="dashboard-content-left">
              <div className="dashboard-content-table">
                <Table
                  scroll={{ x: true }}
                  columns={columns}
                  dataSource={serviceList}
                  rowKey="id"
                  loading={loading}
                  size="small"
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            </div>
            <div className="dashboard-content-right">
              <div className="dashboard-content-booking">
                <div className="vaqt-tanlash-modal">
                  <div className="service-time">
                    <Space>
                      <h3>
                        <b>Servis nomi:</b> {orderTimes?.service?.name}
                      </h3>
                      <h3>
                        <b>Shifokor nomi:</b> {orderTimes?.doctor?.user.fio}
                      </h3>
                    </Space>

                    <div>
                      {orderTimes?.dates.map((el) => (
                        <Card
                          key={el.id}
                          className="hafta-kuni"
                          title={"Sana: " + el.selected_date.slice(0, 10)}
                        >
                          <Card.Grid
                            onClick={() => {
                              changeStatus(el);
                            }}
                            style={gridStyle}
                            className={
                              selectedTimes.includes(el.selected_date)
                                ? "send-time"
                                : "selected-time"
                            }
                          >
                            {el.selected_date.slice(11, 16)}
                          </Card.Grid>
                        </Card>
                      ))}
                    </div>
                  </div>
                  {orderTimes === null || (
                    <button
                      onClick={sendOrderResponse}
                      className="button-64"
                      style={{
                        marginRight: "30px",
                        marginBottom: "30px",
                      }}
                    >
                      <span className="text">Send booking times</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-right">
          {orderList.length ? (
            orderList.map((item) => (
              <div
                key={item.id}
                className={
                  moment(item.created_at).valueOf() +
                    5 * 60 * 1000 -
                    Date.now() <=
                    240000 &&
                  moment(item.created_at).valueOf() +
                    5 * 60 * 1000 -
                    Date.now() >=
                    210000
                    ? "dashboard-booking-card color"
                    : moment(item.created_at).valueOf() +
                        5 * 60 * 1000 -
                        Date.now() <=
                      210000
                    ? "dashboard-booking-card color2"
                    : "dashboard-booking-card"
                }
                onClick={() => {
                  chooseOrder(item);
                }}
              >
                <h1 className="dashbord_h1">{item.doctor.user.fio}</h1>
                <div className="booking-list-box">
                  <Countdown
                    onFinish={onTimeFinish}
                    format="mm:ss"
                    value={moment(item.created_at).valueOf() + 5 * 60 * 1000}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="noZakaz">Buyurtmalar Mavjud Emas</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
