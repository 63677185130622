import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ControlContextProvider } from './components/contexts/ControlContext';

ReactDOM.render(
  <ControlContextProvider>
    <App />
  </ControlContextProvider>,
  document.getElementById('root')
);
