import {
  Modal,
  Table,
  Layout,
  Button,
  Input,
  Tabs,
  Form,
  Select,
  DatePicker,
  Upload,
  InputNumber,
  Space,
  Switch,
  Image,
  Spin,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined, EditFilled, EditOutlined } from "@ant-design/icons";
import "./Doctor.css";
import { GetLanguageConfig } from "../../axios/server/config/GetLanguage";
import Text from "antd/lib/typography/Text";
import {
  DoctorTimeTableConfig,
  EditDocTimeTable,
  EditDoctorConfig,
  GetAllDoctors,
  GetDoctorsByIdConfig,
  getDoctorTimeTableByDoctorId,
  getInfoAcademicTitles,
  getInfoLanguages,
  getInfoSpecialities,
  getInfoSpecializationDirections,
  getInfoSpecializations,
  getTimeTableWeekDays,
  SendDoctor,
} from "../../axios/server/config/DoctorConfig";
import { resHandler } from "../../helper/response-handler";
import { formDataBuilder } from "../../helper/data-builder";
import { fileBuilder } from "../../helper/file-builder";
import { timesBuilder } from "../../helper/timesBuilder";
import { DoctorTimeTableBuilder } from "./DoctorTimeTableModalBuilder";
import moment from "moment";

const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

export const DoctorPage = () => {
  const [lang, setLang] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [speakLanguages, setSpeakLanguages] = useState([]);
  const [academicTitles, setAcademicTitles] = useState([]);
  const [specializationDirections, setSpecializationDirections] = useState([]);
  const [infoLang, setInfoLang] = useState("uz");
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [weekdays, setWeekdays] = useState([]);
  const [doctorCreateInfoForm] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [doctorId, setDoctorId] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [timeTableValues, setTimeTableValues] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoDataSource, setInfoDataSource] = useState({});

  const tableColumns = [
    {
      title: "F.I.Sh.",
      dataIndex: "user",
      render: ({ fio }) => <span>{fio}</span>,
    },
    {
      title: "Tel raqami",
      dataIndex: "user",
      render: ({ phone }) => <span>{phone}</span>,
    },
    {
      title: "TimeTable",
      dataIndex: "id",
      render: (doctorId) => (
        <Button onClick={() => showDoctorTimeTableModal(doctorId)}>
          TimeTableni ko'rish
        </Button>
      ),
    },
    {
      title: "Boshqa ma'lumotlari",
      dataIndex: "user_id",
      render: (doctorId) => (
        <Button onClick={() => showModal(doctorId)}>Batafsil</Button>
      ),
    },
  ];

  const infoColumn = [
    {
      title: "Properties",
      render: (key) => <h3 style={{ textTransform: "capitalize" }}>{key}</h3>,
    },
    {
      title: "Values",
      render: (key) => <div>{dataShower(key)}</div>,
    },
  ];

  useEffect(() => {
    getDataLanguages();
    getAllDoctorsList();
    getInformationList();
    getWeekDays();
  }, []);

  const getWeekDays = () => {
    resHandler({
      promise: getTimeTableWeekDays,
      onSuccess: setWeekdays,
    });
  };

  const getDataLanguages = () => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: (data) => setLang(data.map((v) => v.url)),
    });
  };

  const getAllDoctorsList = (props) => {
    resHandler({
      promise: GetAllDoctors,
      props,
      onSuccess: setDataSource,
      loader: setLoading,
    });
  };

  const getInformationList = () => {
    resHandler({ promise: getInfoSpecialities, onSuccess: setSpecialities });
    resHandler({
      promise: getInfoSpecializations,
      onSuccess: setSpecializations,
    });
    resHandler({ promise: getInfoLanguages, onSuccess: setSpeakLanguages });
    resHandler({
      promise: getInfoAcademicTitles,
      onSuccess: setAcademicTitles,
    });
  };

  const specializationChange = (id) => {
    resHandler({
      promise: getInfoSpecializationDirections,
      props: id,
      onSuccess: (data) => setSpecializationDirections((v) => [...v, ...data]),
    });
  };

  const specializationDeselect = (id) => {
    setSpecializationDirections((v) =>
      v.filter((i) => i.specialization_id !== id)
    );
    doctorCreateInfoForm.setFieldsValue({ directions: [] });
  };

  const resetSearch = () => {
    getAllDoctorsList();
    setSelected(null);
  };
  const rowSelection = {
    type: "radio",
    selectedRowKeys: selected ? [selected] : [],
    onChange: ([key], [item]) => {
      setSelected(key);
      setUserId(item?.user_id);
    },
    hideSelectAll: true,
  };

  const onSearch = (value = "") => {
    getAllDoctorsList(value);
  };

  const showModal = (id) => {
    resHandler({
      promise: GetDoctorsByIdConfig,
      props: id,
      onSuccess: (data) => {
        const values = {
          avatar: data.user?.avatar,
          fio: data.user?.fio,
          birthday: data.user?.birthday,
          phone: data.user?.phone,
          ...data.doctor,
        };
        delete values.id;
        delete values.role_id;
        delete values.created_at;
        delete values.updated_at;
        delete values.user_id;
        delete values.clinic_id;
        delete values.academic_title_id;
        setInfoDataSource(values);
      },
      loader: setLoading,
    });
    setIsModalVisible(true);
  };

  const dataShower = (key) => {
    const data = infoDataSource || {};
    if (data[key] instanceof Array) {
      return data[key].map((val) => val.name).join(",");
    } else if (data[key] instanceof Object && data[key]?.[infoLang]) {
      return data[key][infoLang] || "Malumot yo'q";
    } else if (key === "avatar") {
      return <Image height={50} src={data[key]} alt="avatar" />;
    } else if (data[key] instanceof Object) {
      return data[key]?.name || "Malumot yo'q";
    } else {
      return data[key] || "Malumot yo'q";
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (info) => {
    setFileList(info.fileList);
    setAvatar(info.file);
  };

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.thumbUrl);
  };

  const deleteImg = () => {
    setFileList([]);
    setAvatar(null);
  };
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
    if (birthday) setBirthday(null);
    setIsModalVisible2(true);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
    doctorCreateInfoForm.resetFields();
    setAvatar(null);
    setFileList([]);
  };

  const handleOk = () => {
    doctorCreateInfoForm.validateFields().then((values) => {
      const formData = formDataBuilder(values, lang);
      if (avatar) {
        formData.append("avatar", avatar);
      }
      if (selected !== null) {
        resHandler({
          promise: EditDoctorConfig,
          props: { id: userId, data: formData },
          onSuccess: () => {
            setSelected(null);
            handleCancel2();
            getAllDoctorsList();
          },
          onError: doctorCreateInfoForm.setFields,
          message: true,
        });
      } else {
        resHandler({
          promise: SendDoctor,
          props: formData,
          onSuccess: () => {
            setSelected(null);
            handleCancel2();
            getAllDoctorsList();
          },
          onError: doctorCreateInfoForm.setFields,
          message: true,
        });
      }
    });
  };

  const [birthday, setBirthday] = useState(null);

  const editDoctors = () => {
    resHandler({
      promise: GetDoctorsByIdConfig,
      props: userId,
      onSuccess: (data) => {
        const val = { ...data.user, ...data.doctor };
        const directions = val.directions.map((v) => v.id);
        const academic_title_id = val.academic_title.id;
        const specializations = val.specializations.map((v) => v.id);
        const languages = val.languages.map((v) => v.id);
        const specialties = val.specialty.map((v) => v.id);
        const birthday = moment(data.user?.birthday || "", "YYYY-MM-DD");
        const values = {
          ...val,
          directions,
          academic_title_id,
          specialties,
          languages,
          specializations,
        };
        setBirthday(birthday);
        specializations.forEach((id) => {
          specializationChange(id);
        });
        const file = fileBuilder({ values, key: "avatar" });
        setFileList([file]);
        doctorCreateInfoForm.setFieldsValue(values);
      },
      loader: setLoading,
    });
    setIsModalVisible2(true);
  };

  const handleCanselTimetable = () => {
    setDoctorTimeTableVisible(false);
  };

  const cancelDoctorTimeTAbleModal = () => {
    setDoctorTimeTableModal(false);
    closeEditMode();
    setDoctorTimeTable(null);
  };
  const [doctorTimeTableVisible, setDoctorTimeTableVisible] = useState(false);
  const showDoctorTimeTable = () => {
    setDoctorTimeTableVisible(true);
    cancelDoctorTimeTAbleModal();
  };
  const [doctorTimeTableModal, setDoctorTimeTableModal] = useState(false);

  const [doctorTimeTable, setDoctorTimeTable] = useState(null);

  const showDoctorTimeTableModal = (id) => {
    setDoctorId(id);
    setDoctorTimeTableModal(true);
    resHandler({
      promise: getDoctorTimeTableByDoctorId,
      props: id,
      loader: setSpinner,
      onSuccess: setDoctorTimeTable,
    });
  };

  const editTimeTable = () => {
    setEditMode(true);
    const timeTable = { ...doctorTimeTable };

    weekdays.forEach((day) => {
      if (!timeTable.weekdays.find((v) => v.weekday_id === day.id)) {
        timeTable?.weekdays.push({
          weekday_id: day.id,
          busy_times: [],
          service_times: [],
        });
      }
    });
    setDoctorTimeTable(timeTable);
  };

  const closeEditMode = () => {
    setEditMode(false);
  };

  const timeClick = ({ target }) => {
    if (editMode) {
      const {
        dataset: { time, day, color },
      } = target;
      if (time && color) {
        const table = { ...doctorTimeTable };
        table.weekdays.forEach((weekDay) => {
          if (weekDay.weekday_id.toString() === day) {
            if (color === "default") {
              weekDay.busy_times.push({ time, doctor_time_table_id: "1" });
            } else {
              weekDay.busy_times = weekDay.busy_times.filter(
                (v) => v.time.slice(0, 5) !== time.slice(0, 5)
              );
            }
          }
        });
        setDoctorTimeTable(table);
      }
    }
  };

  const saveEdited = () => {
    sendTimeTable(doctorTimeTable.weekdays);
  };

  const sendTimeTable = (times) => {
    const formData = new FormData();
    formData.append("doctor_id", doctorId);
    let i = 0;
    times.forEach((day) => {
      if (day.busy_times.length) {
        day.busy_times.forEach((time) => {
          formData.append(
            `doctor_busy_times[${i}][time]`,
            time.time.slice(0, 5)
          );
          formData.append(
            `doctor_busy_times[${i}][weekday_id]`,
            day.weekday_id
          );
          formData.append(`weekdays[${i}]`, day.weekday_id);
          i = i + 1;
        });
      }
    });
    if (editMode) {
      formData.append("start_time", doctorTimeTable.start_time?.slice(0, 5));
      formData.append("end_time", doctorTimeTable.end_time?.slice(0, 5));
      formData.append("interval", doctorTimeTable.interval);
      resHandler({
        promise: EditDocTimeTable,
        props: { data: formData, id: doctorTimeTable.id },
        onSuccess: cancelDoctorTimeTAbleModal,
        message: true,
      });
    } else {
      formData.append("start_time", timeTableValues.start);
      formData.append("end_time", timeTableValues.end);
      formData.append("interval", timeTableValues.interval);
      resHandler({
        promise: DoctorTimeTableConfig,
        props: formData,
        onSuccess: handleCanselTimetable,
        message: true,
      });
    }
  };

  return (
    <div className="doctors">
      <Modal
        title="Doktorni malumotlarini kiritish"
        visible={isModalVisible2}
        centered
        width="70%"
        onOk={handleOk}
        onCancel={handleCancel2}
      >
        <Form
          scrollToFirstError={true}
          layout="vertical"
          form={doctorCreateInfoForm}
          initialValues={{
            is_seen: true,
          }}
        >
          <Tabs destroyInactiveTabPane={false} defaultActiveKey="1" centered>
            <TabPane
              forceRender
              className="flex-center"
              tab="Asosiy malumotlari"
              key="1"
            >
              {lang.map((item) => (
                <div key={item} className="width-60">
                  <h3 style={{ margin: "30px 0" }}>
                    <Text code>{item}</Text> da kiritiladigan ma'lumotlar
                  </h3>
                  <Form.Item
                    label={item === "ru" ? "Имя" : "Ismi"}
                    name={["fio", item]}
                    key={[item, "name"]}
                    rules={[
                      { required: true, message: "Doktor ismini kiriting!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ))}
              <div className="width-60">
                <Form.Item
                  label="Mutaxasislikni tanlang"
                  name="specialties"
                  key="specialityIdList"
                  rules={[
                    {
                      required: true,
                      message: "Iltimos doktorning mutaxasisliklarini tanlang",
                    },
                  ]}
                >
                  <Select mode="multiple">
                    {specialities.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Doktor uchun username yarating"
                  name="username"
                  key="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                {selected === null ? (
                  <Form.Item
                    label="Doktor uchun password yarating"
                    name="password"
                    key="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                ) : null}
                <Form.Item
                  label="Doktor rasmi:"
                  key="Doktor rasmi:"
                  className="image-upload"
                >
                  <Upload
                    fileList={fileList}
                    listType="picture-card"
                    onPreview={(file) => handlePreview(file)}
                    onChange={handleChange}
                    onRemove={deleteImg}
                    beforeUpload={() => false}
                    maxCount={1}
                    accept=".jpg, .jpeg, .png"
                  >
                    <div>Rasm yuklang</div>
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={() => setPreviewVisible(false)}
                    cancelText="Yopish"
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                </Form.Item>
                <Form.Item
                  label="Jinsi"
                  name="gender"
                  key="genderEnum"
                  rules={[
                    { required: true, message: "Doktor jinsini tanlang!" },
                  ]}
                >
                  <Select>
                    <Option value="male" key="MALE">
                      Erkak
                    </Option>
                    <Option value="female" key="FEMALE">
                      Ayol
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Doktorni Websahifada ko'rsatish"
                  name="is_seen"
                  valuePropName="checked"
                  key="showDoctor"
                >
                  <Switch defaultChecked />
                </Form.Item>
              </div>
            </TabPane>
            <TabPane
              forceRender={true}
              className="flex-center"
              tab="Shaxsiy malumotlari"
              key="4"
            >
              <div className="width-60">
                <Form.Item
                  getValueFromEvent={(e, v) => {
                    return v;
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="Tug'ilgan sanasi:"
                  name="birthday"
                  key="birthDate"
                  valuePropName="date"
                >
                  <DatePicker onChange={setBirthday} value={birthday} />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Doktor biladigan tillarni kiriting",
                    },
                  ]}
                  label="Qanday tillarni biladi"
                  name="languages"
                  key="speakingLanguages"
                >
                  <Select mode="multiple">
                    {speakLanguages.map((el) => (
                      <Option value={el.id} key={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Kontakt malumotlari (tel)"
                  name="phone"
                  key="contactInfo"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 12,
                      max: 12,
                    },
                  ]}
                >
                  <Input maxLength={12} />
                </Form.Item>
              </div>
              <div className="width-60">
                <Form.Item
                  label={"Ixtisosligi"}
                  name="specializations"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    onSelect={specializationChange}
                    onDeselect={specializationDeselect}
                    mode="multiple"
                  >
                    {specializations.map((el) => (
                      <Option value={el.id} key={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={"Doktor mutaxasisliklarini kiriting"}
                  name="directions"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select mode="multiple">
                    {specializationDirections.map((el) => (
                      <Option value={el.id} key={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </TabPane>
            <TabPane
              forceRender={true}
              className="flex-center"
              tab="Ta'lim jarayoni"
              key="2"
            >
              <div className="width-60">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Muaxasislik bo'yicha ish stajini kiriting",
                    },
                  ]}
                  label="Muaxasislik bo'yicha ish staji( Стаж работы по специальности (лет) )"
                  name="specialty_experience"
                  key="anyWorkingExperience"
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true }]}
                  label="Aynan shu muaxasislik bo'yicha ish staji (Стаж работы по данной специализации (лет))"
                  name="exactly_specialty_experience"
                  key="yearsExperience"
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Qancha muddat amaliyot o'tagan( Стажировки (лет) )"
                  name="internships"
                  key="internCompany"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true }]}
                  label="Diplom seriyasi(Идентификационный номер диплома)"
                  name="diplom_series"
                  key="diploma"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Ilmiy ishlari soni (Научные работы (количество шт)):"
                  name="scientific_works"
                  key="scientificWorks"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Akademik unvoni (Ученое звание):"
                  name="academic_title_id"
                  key="academicPosition"
                >
                  <Select>
                    {academicTitles.map((el) => (
                      <Option value={el.id} key={el.id}>
                        {el.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              {lang.map((item) => (
                <div key={item} className="width-60">
                  <h3 style={{ margin: "30px 0" }}>
                    <Text code>{item}</Text> da kiritiladigan ma'lumotlar
                  </h3>

                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Где получил образование"
                        : "Tamomlagan bilim yurti"
                    }
                    name={["university", item]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Повышение квалификации"
                        : "Malaka oshirish joyi"
                    }
                    name={["professional_development", item]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru" ? "Ученая степень" : "Akademik darajasi"
                    }
                    name={["academic_degree", item]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Учебные курсы"
                        : "Qanday o'quv kurslarini bitirgan"
                    }
                    name={["training_courses", item]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Прежние места работы:"
                        : "Oldingi ish joyi:"
                    }
                    name={["previous_workplace", item]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    rules={[{ required: true }]}
                    label={item === "ru" ? "Грамоты:" : "Erishgan yutuqlari:"}
                    name={["achievements", item]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ))}
            </TabPane>
            <TabPane
              forceRender={true}
              className="flex-center"
              tab="Tajribasi"
              key="3"
            >
              <div className="width-60">
                <Form.Item
                  rules={[{ required: true }]}
                  label="Davolagan bemorlari soni(Ориентировочное количество больных получивших консультацию):"
                  name="treated_patients"
                  key="patientsRecovered"
                >
                  <InputNumber />
                </Form.Item>
              </div>
              {lang.map((item) => (
                <div key={item} className="width-60">
                  <h3 style={{ margin: "30px 0" }}>
                    <Text code>{item}</Text> da kiritiladigan ma'lumotlar
                  </h3>

                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Doktor toifasini kiriting",
                      },
                    ]}
                    label={item === "ru" ? "Категория" : "Toifasi qanday:"}
                    name={["category", item]}
                    key={[item, "category"]}
                  >
                    <Select>
                      <Option
                        value={item === "ru" ? "Высшая" : "Oliy"}
                        key="oliy"
                      >
                        {item === "ru" ? "Высшая" : "Oliy"}
                      </Option>
                      <Option
                        value={item === "ru" ? "Первая" : "Birinchi"}
                        key="birinchi"
                      >
                        {item === "ru" ? "Первая" : "Birinchi"}
                      </Option>
                      <Option
                        value={item === "ru" ? "Вторая" : "Ikkinchi"}
                        key="ikkinchi"
                      >
                        {item === "ru" ? "Вторая" : "Ikkinchi"}
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "На каких видах медоборудования имеет право и квалификацию работать:"
                        : "Qanday tibbiy uskunalardan foydalana oladi:"
                    }
                    name={["used_medical_equipment", item]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Какие болезни лечит:"
                        : "Qanday kasalliklarni davolagan:"
                    }
                    name={["treated_diseases", item]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru"
                        ? "Какие лечебные процедуры проводит:"
                        : "Davolash usullari qanday:"
                    }
                    name={["methods_treatment", item]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={
                      item === "ru" ? "Кому подчиняется:" : "Kimga bo'ysunadi:"
                    }
                    name={["to_whom_obey", item]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              ))}
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
      <Layout style={{ backgroundColor: "white" }}>
        <div className="doctors-head">
          <h2>Shifokorlar</h2>
          {selected !== null ? (
            <Space size="small">
              <Button
                onClick={editDoctors}
                type="primary"
                icon={<EditFilled />}
              >
                Doktorni tahrirlash
              </Button>
              <Button
                shape="round"
                onClick={() => setSelected(null)}
                icon={<CloseOutlined />}
              >
                Yopish
              </Button>
            </Space>
          ) : (
            <Button onClick={showModal2} type="primary">
              Doctor qo'shish
            </Button>
          )}
          <div className="doctors-search">
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              enterButton
            />
            <Button
              style={{ marginLeft: "20px" }}
              onClick={resetSearch}
              type="primary"
            >
              Reset
            </Button>
          </div>
        </div>
      </Layout>
      <Table
        scroll={{ x: true }}
        rowKey="id"
        loading={loading}
        rowSelection={rowSelection}
        columns={tableColumns}
        dataSource={dataSource}
      />

      <Modal
        title="Doktor haqida ma'lumotlar"
        visible={isModalVisible}
        centered
        width="70%"
        onOk={handleCancel}
        onCancel={handleCancel}
        className="doktor-info-modal"
      >
        <div className="doktor-info-content">
          <Select
            defaultValue="uz"
            style={{ width: 120 }}
            onChange={(v) => setInfoLang(v)}
          >
            {lang.map((el) => (
              <Option value={el} key={el}>
                {el.toUpperCase()}
              </Option>
            ))}
          </Select>
          <Table
            rowKey={(v) => v}
            columns={infoColumn}
            dataSource={Object.keys(infoDataSource)}
          />
        </div>
      </Modal>

      <DoctorTimeTableBuilder
        onOk={sendTimeTable}
        onCancel={handleCanselTimetable}
        isShow={doctorTimeTableVisible}
        weekDays={weekdays}
        setTimeTableValues={setTimeTableValues}
      />

      <Modal
        visible={doctorTimeTableModal}
        centered
        width="70%"
        onCancel={cancelDoctorTimeTAbleModal}
        title={
          <Space wrap>
            <Space wrap>
              <b>Doktor time table haqida ma'lumot</b>
              {doctorTimeTable ? (
                <>
                  {editMode ? (
                    <Button icon={<CloseOutlined />} onClick={closeEditMode}>
                      Tahrirlashni yopish
                    </Button>
                  ) : (
                    <Button
                      icon={<EditOutlined />}
                      onClick={editTimeTable}
                      type="primary"
                    >
                      Tahrirlash
                    </Button>
                  )}
                </>
              ) : null}
            </Space>
            <Space wrap>
              <b>Yo'riqnoma:</b>
              <Tag color="green">Servis vaqti</Tag>
              <Tag color="red">Doktor band vaqti</Tag>
              <Tag>Bo'sh vaqt</Tag>
            </Space>
          </Space>
        }
        okButtonProps={{ disabled: !editMode }}
        okText="Saqlash"
        onOk={saveEdited}
      >
        <Tabs defaultActiveKey="0" centered>
          {doctorTimeTable ? (
            doctorTimeTable?.weekdays.map((day, i) => (
              <TabPane
                tab={weekdays.find((v) => v.id === day.weekday_id).name}
                key={i}
              >
                <Spin spinning={spinner}>
                  <Space wrap style={{ padding: "1rem" }} onClick={timeClick}>
                    {timesBuilder(day, doctorTimeTable).map((time, index) => (
                      <>
                        {time.service_id !== undefined ? (
                          <Tag
                            className="tag-service"
                            key={index}
                            color="green"
                          >
                            {time.time.slice(0, 5)}
                          </Tag>
                        ) : (
                          <Tag
                            data-day={day.weekday_id}
                            data-time={time.time}
                            data-color={
                              time.doctor_time_table_id === undefined
                                ? "default"
                                : "red"
                            }
                            style={{
                              padding: "0.3rem 0.5rem",
                              fontSize: "1rem",
                              cursor: editMode ? "pointer" : "default",
                            }}
                            key={index}
                            color={
                              time.doctor_time_table_id === undefined
                                ? "default"
                                : "red"
                            }
                          >
                            {time.time.slice(0, 5)}
                          </Tag>
                        )}
                      </>
                    ))}
                  </Space>
                </Spin>
              </TabPane>
            ))
          ) : (
            <TabPane tab="">
              <Spin spinning={spinner}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3>Doktor time table mavjud emas</h3>
                  <Button type="primary" onClick={showDoctorTimeTable}>
                    Time Table qo'shish
                  </Button>
                </div>
              </Spin>
            </TabPane>
          )}
        </Tabs>
      </Modal>
    </div>
  );
};
