import { message } from 'antd';

const messageErr = (err = "Xatolik yuz berdi, qayta urunib ko'ring") => {
  return message.error(err, 3);
};

const infoMessage = (text = '') => {
  return message.success(text, 3);
};

const errorMessage = (text = '') => {
  return message.error(text, 3);
};

export { infoMessage, errorMessage, messageErr };
