import React, { useState } from "react";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Tabs,
  Tag,
  TimePicker,
} from "antd";
import { findTimeArray } from "../../helper/findTime";

const format = "HH:mm";
const { TabPane } = Tabs;
const { Option } = Select;

export const TimeTableModalBuilder = ({
  isShow,
  onOk,
  onCancel,
  weekDays,
  serviceDoctorChange,
  serviceDoctorId,
  serviceDoctors,
}) => {
  const [generatedTimes, setGeneratedTimes] = useState([]);
  const [times, setTimes] = useState([]);

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      start: fieldsValue["start"].format(format),
      end: fieldsValue["end"].format(format),
    };
    setTimes([]);
    generateTimeTables(values);
  };

  const generateTimeTables = ({ start, end, interval }) => {
    if (start && end && interval) {
      const timeArray = findTimeArray({ start, end, interval });
      setGeneratedTimes(timeArray);
    }
  };

  const cancel = () => {
    setGeneratedTimes([]);
    setTimes([]);
    onCancel();
  };

  const timeClick = ({ target }) => {
    const {
      dataset: { time, day },
    } = target;
    if (time) {
      let days = times;
      let arr;
      if (!times.length) {
        days = weekDays.map(({ id }) => ({
          weekday_id: id,
          service_times: [],
        }));
        setTimes(days);
      }
      if (
        days
          .find((v) => v.weekday_id.toString() === day)
          ?.service_times.find((v) => v.time === time)
      ) {
        arr = times.map((weekDay) => {
          if (weekDay.weekday_id.toString() === day) {
            weekDay.service_times = weekDay.service_times.filter(
              (v) => v.time !== time
            );
          }
          return weekDay;
        });
      } else {
        arr = days.map((weekDay) => {
          return weekDay.weekday_id.toString() === day
            ? {
                ...weekDay,
                service_times: [...weekDay.service_times, { time }],
              }
            : weekDay;
        });
      }
      setTimes(arr);
    }
  };

  const ok = () => {
    onOk(times);
    setGeneratedTimes([]);
    setTimes([]);
  };

  return (
    <Modal
      title="Service time tableni yaratish"
      visible={isShow}
      centered
      width="70%"
      onOk={ok}
      onCancel={cancel}
      okText="Saqlash"
    >
      <div style={{ marginBottom: "1rem" }}>
        <Form onFinish={onFinish} layout="inline">
          <Form.Item name="start" rules={[{ required: true, type: "object" }]}>
            <TimePicker format={format} placeholder="Start time" />
          </Form.Item>
          <Form.Item name="end" rules={[{ required: true, type: "object" }]}>
            <TimePicker format={format} placeholder="End time" />
          </Form.Item>
          <Form.Item name="interval" rules={[{ required: true }]}>
            <InputNumber placeholder="Interval" />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Generate Times
          </Button>
        </Form>
      </div>
      <Tabs
        defaultActiveKey="0"
        centered
        tabBarExtraContent={
          <Select
            placeholder="doktorni tanlang"
            value={serviceDoctorId}
            onChange={serviceDoctorChange}
          >
            {serviceDoctors.map((doctor) => (
              <Option key={doctor.id} value={doctor.id}>
                {doctor.user.fio}
              </Option>
            ))}
          </Select>
        }
      >
        {weekDays.map((day, i) => (
          <TabPane tab={day.name} key={i}>
            <div>
              <Space wrap style={{ padding: "1rem 0" }} onClick={timeClick}>
                {generatedTimes.map((time) => (
                  <Tag
                    data-day={day.id}
                    data-time={time}
                    style={{
                      padding: "0.3rem 0.5rem",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    key={time}
                    color={
                      times
                        .find((v) => v.weekday_id === day.id)
                        ?.service_times.find(
                          (v) => v.time?.slice(0, 5) === time
                        )
                        ? "green"
                        : "default"
                    }
                  >
                    {time}
                  </Tag>
                ))}
              </Space>
            </div>
          </TabPane>
        ))}
      </Tabs>
    </Modal>
  );
};
