import React, { useState, useEffect, useCallback } from "react";
import "./Info.css";
import { Input, Upload, Form, Modal, Select, Button } from "antd";
import { ClinicEdit, ClinicGet } from "../../axios/server/config/ClinicCrud";
import { GetLanguageConfig } from "../../axios/server/config/GetLanguage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getRegions } from "../../axios/server/config/RegionCrud";
import { getDistrictsbyRegion } from "../../axios/server/config/DistrictCrud";
import {
  YMaps,
  Map,
  ZoomControl,
  FullscreenControl,
  SearchControl,
  GeolocationControl,
  Placemark,
} from "react-yandex-maps";
import { YANDEX_QUERY } from "../../axios/constants";
import { resHandler } from "../../helper/response-handler";
import { fileBuilder } from "../../helper/file-builder";
import { formDataBuilder } from "../../helper/data-builder";

const { TextArea } = Input;
const { Option } = Select;

const initialCoordinates = [41.311081, 69.240562];
const initialCKEditorValue = { uz: "<p></p>", ru: "<p></p>" };

const Info = () => {
  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const [ckData, setCkData] = useState(initialCKEditorValue);
  const [languages, setLanguages] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [regionList, setRegion] = useState([]);
  const [districtList, setDistrict] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [removedDocuments, setRemovedDocuments] = useState([]);

  const [formInfo] = Form.useForm();

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.thumbUrl);
  };

  const fileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const fileRemove = (file) => {
    if (file.isBuild) {
      setRemovedImages((v) => [...v, file.id]);
    }
  };

  const documentChange = ({ fileList }) => {
    setDocumentList(fileList);
  };

  const documentRemove = (file) => {
    if (file.isBuild) {
      setRemovedDocuments((v) => [...v, file.id]);
    }
  };

  const getClinic = useCallback(() => {
    resHandler({
      promise: ClinicGet,
      onSuccess: (data) => {
        changeRegion(data.region_id);
        setCkData(data.description);
        setCoordinates([data.location_y, data.location_x]);
        const photos = fileBuilder({
          values: data,
          key: "images",
          single: false,
          fileKey: "image",
        });
        setFileList(photos);

        const docs = fileBuilder({
          values: data,
          key: "documents",
          single: false,
          fileKey: "document",
        });
        setDocumentList(docs);
        formInfo.setFieldsValue(data);
      },
    });
  }, [formInfo]);

  const saveChanges = () => {
    formInfo.validateFields().then((values) => {
      const formData = formDataBuilder(
        values,
        languages.map((v) => v.url)
      );

      formData.append("location_y", coordinates[0]);
      formData.append("location_x", coordinates[1]);

      fileList.forEach((file) => {
        if (!file.isBuild) {
          formData.append("images[]", file.originFileObj);
        }
      });

      removedImages.forEach((id) => {
        formData.append("remove_images[]", id);
      });

      documentList.forEach((file) => {
        if (!file.isBuild) {
          formData.append("documents[]", file.originFileObj);
        }
      });

      removedDocuments.forEach((id) => {
        formData.append("remove_documents[]", id);
      });

      resHandler({
        promise: ClinicEdit,
        props: formData,
        onSuccess: getClinic,
        message: true,
        onError: formInfo.setFields,
      });
    });
  };

  const regionsGet = () => {
    resHandler({
      promise: getRegions,
      onSuccess: setRegion,
    });
  };

  const changeRegion = (value) => {
    resHandler({
      promise: getDistrictsbyRegion,
      props: value,
      onSuccess: setDistrict,
    });
  };

  const getLanguages = () => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: setLanguages,
    });
  };

  useEffect(() => {
    getLanguages();
    regionsGet();
    getClinic();
  }, [getClinic]);

  return (
    <div className="info_section">
      <div className="info-content">
        <h2 className="info-left-header">Shifoxona haqidagi ma'lumotlar:</h2>
        <Form layout="vertical" form={formInfo}>
          {languages.map(({ url }, index) => (
            <div key={index} className="info_langugage__parents">
              <div
                className="info_language__div"
                style={{
                  border: "1px solid grey",
                  borderRadius: "20px",
                  width: "230px",
                  textAlign: "center",
                }}
              >
                <h1 style={{ display: "inline-block" }}>{url}</h1> da
                kiritiladigan ma'lumotlar
              </div>

              <Form.Item
                name={["name", url]}
                label={`Shifoxona Nomini Kiriting (${url})`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
                key={`ShifoxonaNomi${index}`}
              >
                <Input size="large" placeholder={`Shifoxona ${url} nomi...`} />
              </Form.Item>

              <Form.Item
                name={["med_diagnostic_technologies", url]}
                label={`Tahlil va diagnostika uchun tibbiy asbob -uskunalarni kiriting (${url})`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
                key={`Tahlil(${url})`}
              >
                <Input
                  size="large"
                  placeholder={`Tibbiy asbob -uskunalar ${url} nomi...`}
                />
              </Form.Item>

              <Form.Item
                name={["med_treatment_technologies", url]}
                label={`Tibbiy muolajalar uchun tibbiy asbob - uskunalarni kiriting (${url})`}
                key={`Tibbiy(${url})`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
              >
                <Input
                  size="large"
                  placeholder={`Tibbiy muolajalar uchun asbob -uskunalar ${url} nomi...`}
                />
              </Form.Item>

              <Form.Item
                name={["legal_information", url]}
                label={`Shifoxonani haqidagi qonuniy ma'lumotlarini kiriting (${url})`}
                key={`qonuniy${url}`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
              >
                <TextArea
                  rows={4}
                  size="large"
                  placeholder={`Huquqiy ma'lumotlar ${url} `}
                />
              </Form.Item>

              <Form.Item
                label={`Shifoxona haqida ma'lumotlarni ${url}da kiriting:`}
                key={`Shifoxona${url}da`}
                name={["description", url]}
                getValueFromEvent={(_, editor) => editor.getData()}
                initialValue={ckData[url]}
              >
                <CKEditor data={ckData[url]} editor={ClassicEditor} />
              </Form.Item>
            </div>
          ))}

          <div className="info_title">Umumiy ma'lumotlar</div>

          <Form.Item
            name="client_discount"
            label="Mijoz uchun ajratilgan foiz miqdori"
            rules={[{ required: true, message: "To'ldirilmagan" }]}
          >
            <Input type="number" size="large" placeholder="Mijoz uchun (%)" />
          </Form.Item>

          <Form.Item
            name="total_discount"
            label="Shifoxona uchun ajratilgan foiz miqdori"
            rules={[{ required: true, message: "To'ldirilmagan" }]}
          >
            <Input
              type="number"
              size="large"
              placeholder="Shifoxona uchun (%)"
            />
          </Form.Item>

          <Form.Item
            name="count_analysis"
            label="Amalga oshirilgan analizlarning umumiy sonini kiriting"
          >
            <Input type="number" size="large" placeholder="Analizlar soni" />
          </Form.Item>

          <Form.Item
            name="count_beds"
            label="Yotish uchun mo'ljanlangan jihozlar sonini kiriting"
          >
            <Input
              type="number"
              size="large"
              placeholder="Yotish uchun mo'ljanlangan jihozlar soni"
            />
          </Form.Item>

          <Form.Item name="count_staff" label="Xodimlar sonini kiriting">
            <Input type="number" size="large" placeholder="Xodimlar soni" />
          </Form.Item>

          <Form.Item name="count_doctors" label="Do'ktorlar sonini kiriting">
            <Input type="number" size="large" placeholder="Do'ktorlar soni" />
          </Form.Item>

          <Form.Item
            name="treated_patients"
            label="Shifoxonada davolangan be'morlar sonini kiriting"
          >
            <Input
              type="number"
              size="large"
              placeholder="Davolangan be'morlar soni"
            />
          </Form.Item>

          <Form.Item name="license" label="Shifoxona litsenziyasini kiriting">
            <Input
              type="number"
              size="large"
              placeholder="Shifoxona litsenziyasi"
            />
          </Form.Item>

          <Form.Item name="official_date" label="Rasmiy sanani kiriting">
            <Input size="large" placeholder="Rasmiy sana" />
          </Form.Item>

          <Form.Item
            name="total_building"
            label="Shifoxona binolarining umumiy maydonini kiriting (m2)"
          >
            <Input
              type="number"
              size="large"
              placeholder="Binolarining umumiy maydonini"
            />
          </Form.Item>

          <Form.Item
            name="total_territory"
            label="Shifoxona hududining umumiy maydonini kiriting (m2)"
          >
            <Input
              type="number"
              size="large"
              placeholder="Hududining umumiy maydonini"
            />
          </Form.Item>

          <div className="clinic_modal__location">
            <div className="clinic_modal__region">
              <Form.Item name="region_id" label="Klinika hududini tanlang:">
                <Select
                  key="select"
                  placeholder="Klinika joylashgan viloyatni tanlang"
                  onChange={changeRegion}
                >
                  {regionList.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="clinic_modal__district">
              <Form.Item name="district_id" label="Tuman">
                <Select placeholder="Klinika joylashgan tumanni tanlang">
                  {districtList.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Shifoxona uchun rasmlar tanlang:"
            key="Shifoxonauchunrasmlar"
            className="image-upload"
          >
            <Upload
              multiple={true}
              fileList={fileList}
              listType="picture"
              onChange={fileChange}
              onRemove={fileRemove}
              onPreview={handlePreview}
              beforeUpload={() => false}
              accept=".jpg, .jpeg, .png, .svg, .gif"
            >
              <Button type="primary">Rasmni yuklang</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Shifoxona uchun hujjatlar:"
            className="image-upload"
          >
            <Upload
              multiple={true}
              fileList={documentList}
              onChange={documentChange}
              onRemove={documentRemove}
              beforeUpload={() => false}
            >
              <Button type="primary">Hujjatni yuklang</Button>
            </Upload>
          </Form.Item>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
            cancelText="Yopish"
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>

          <h4>Klinika joylashgan joyni belgilang:</h4>
          <YMaps query={YANDEX_QUERY}>
            <Map
              onClick={(e) => setCoordinates(e.get("coords"))}
              style={{ width: "100%", height: "70vh" }}
              defaultState={{ center: coordinates, zoom: 13 }}
            >
              <Placemark geometry={coordinates} />
              <ZoomControl />
              <FullscreenControl />
              <SearchControl />
              <GeolocationControl />
            </Map>
          </YMaps>
        </Form>

        <button
          style={{ marginTop: "50px", marginRight: "20px" }}
          onClick={saveChanges}
          className="button-64"
        >
          <span className="text">Save Changes</span>
        </button>
      </div>
    </div>
  );
};

export default Info;
