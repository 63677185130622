// const WEB_SOCKET_PORT = 9005;
// const IP_ADDRESS = '92.63.206.30';
export const TOKEN = "MEDPAY_CLINIC_TOKEN";
export const API_URL = `https://welse.uz/api/`;
export const API_LOGIN = "https://welse.uz/api/";
export const SITE_LANG = "MEDPAY_CLICNIC_LANGUAGE";
// export const WEB_SOCKET_URL = `http://${IP_ADDRESS}:${WEB_SOCKET_PORT}/mb-websocket`;
export const YANDEX_QUERY = {
  apikey: "85aba7bf-89da-4d63-a85d-259e200c60ec",
  lang: "ru_RU",
};
