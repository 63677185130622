import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  Progress,
  Select,
  Space,
  Switch,
} from "antd";
import { resHandler } from "../../helper/response-handler";
import {
  createService,
  getDoctorByService,
  getServiceCategoryList,
} from "../../axios/server/config/StandartServiceConfig";
import { Link } from "react-router-dom";
import "./service-add.css";

const { Option } = Select;

export const ServiceBuilder = () => {
  const [allCategory, setAllCategory] = useState([]);
  const [formSubCategories, setFormSubCategories] = useState([]);
  const [formServices, setFormServices] = useState([]);
  const [formServiceDoctors, setFormServiceDoctors] = useState({});
  const [responses, setResponses] = useState({});
  const [selected, setSelected] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    resHandler({
      promise: getServiceCategoryList,
      onSuccess: setAllCategory,
    });
  };

  const formCategoryChange = (v) => {
    form.resetFields(["subCategory"]);
    setSelected([]);
    setFormServices([]);
    resHandler({
      promise: getServiceCategoryList,
      props: v,
      onSuccess: setFormSubCategories,
    });
  };

  const formSubCategoryChange = (v) => {
    setSelected([]);
    setSubCategoryId(v);
    resHandler({
      promise: getServiceCategoryList,
      props: v,
      onSuccess: (data) => {
        setFormServices(data);
        if (!data.length) {
          if (!empty) setEmpty(true);
        } else {
          if (empty) setEmpty(false);
        }
      },
    });
  };

  const formServiceChange = (props) => {
    resHandler({
      promise: getDoctorByService,
      props: props,
      onSuccess: (data) => {
        setFormServiceDoctors({ ...formServiceDoctors, [props]: data });
      },
    });
  };

  const onsubmit = (values) => {
    selected.forEach((id) => {
      const formData = new FormData();
      formData.append("service_category_id", id);
      formData.append("price", values[`price/${id}`]);
      formData.append("status", values[`status/${id}`] === false ? "0" : "1");
      if (values[`doctors/${id}`]) {
        values[`doctors/${id}`]?.forEach((doctor) => {
          formData.append("doctors[]", doctor);
        });
      }
      sendService(formData, id);
    });
  };

  const sendService = (formData, id) => {
    resHandler({
      promise: createService,
      props: formData,
      message: true,
      onError: (data) => {
        form.setFields(data);
        setResponses((v) => ({ ...v, [id]: "exception" }));
      },
      onSuccess: () => {
        setResponses((v) => ({ ...v, [id]: "success" }));
        resHandler({
          promise: getServiceCategoryList,
          props: subCategoryId,
          onSuccess: setFormServices,
        });
      },
    });
  };

  const onChange = (e, id) => {
    if (e.target.checked) {
      setSelected([...selected, id]);
      formServiceChange(id);
    } else {
      setSelected(selected.filter((v) => v !== id));
    }
  };

  return (
    <div>
      <Space align="center" size="large" style={{ marginBottom: "1rem" }}>
        <h2 style={{ margin: 0 }}>
          <b>Service qo'shish</b>
        </h2>
        <Link to="/service">
          <Button type="primary">Servislarga qaytish</Button>
        </Link>
      </Space>
      <Form form={form} layout="vertical" onFinish={onsubmit}>
        <div>
          <Space size="large">
            <Form.Item name="category" label="Servis kategoriyasi:">
              <Select
                placeholder="Kategoriyani tanlang"
                onChange={formCategoryChange}
              >
                {allCategory.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="subCategory"
              required
              label="Servis subkategoriyasi:"
            >
              <Select
                placeholder="Subkategoriyani tanlang"
                onChange={formSubCategoryChange}
              >
                {formSubCategories.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </div>

        <Space direction="vertical">
          {empty && <h1>Servis mavjud emas</h1>}
          {formServices.map((service, index) => (
            <Space key={service.id} className="services_block">
              <Form.Item label={!index ? "Servis" : null}>
                <Button className="checkbox_btn">
                  <Checkbox onChange={(e) => onChange(e, service.id)}>
                    {service.name}
                  </Checkbox>
                  {responses[service.id] && (
                    <Progress
                      status={responses[service.id] || "success"}
                      type="circle"
                      percent={100}
                      width={20}
                    />
                  )}
                </Button>
              </Form.Item>
              {selected.includes(service.id) && (
                <>
                  <Form.Item
                    label={!index ? "Servis narxi:" : null}
                    name={`price/${service.id}`}
                    rules={[
                      {
                        required: selected.includes(service.id),
                        message: "Servis narxini kiriting!",
                      },
                    ]}
                  >
                    <InputNumber style={{ minWidth: 150 }} placeholder="1000" />
                  </Form.Item>
                  <Form.Item
                    label={!index ? "Status:" : null}
                    name={`status/${service.id}`}
                    valuePropName="checked"
                  >
                    <Switch defaultChecked />
                  </Form.Item>
                  <Form.Item
                    label={!index ? "Doktorni tanlang::" : null}
                    name={`doctors/${service.id}`}
                    rules={[
                      {
                        required: selected.includes(service.id),
                        message: "Please input doktors!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{ minWidth: 400 }}
                      placeholder="Servis doktorlarini tanlang"
                    >
                      {formServiceDoctors[service.id]?.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.user?.fio}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
            </Space>
          ))}
        </Space>

        {formServices.length ? (
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button
                disabled={!selected.length}
                type="primary"
                htmlType="submit"
              >
                Saqlash
              </Button>
            </Form.Item>
          </div>
        ) : null}
      </Form>
    </div>
  );
};
