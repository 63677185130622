import { HttpRequestHub } from '../HttpRequestHub';

export const ClinicGet = () => {
  const config = {
    method: 'GET',
    url: 'reception/clinic/show',
  };
  return HttpRequestHub(config);
};

export const ClinicEdit = (data) => {
  const config = {
    method: 'POST',
    url: 'reception/clinic/update',
    data,
  };
  return HttpRequestHub(config);
};
