import { errorMessage, infoMessage } from "../Notifications/message";
import { errorHandler } from "./error-handler";

export const resHandler = ({
  promise,
  props,
  onSuccess,
  loader,
  message,
  onError,
}) => {
  if (loader) {
    loader(true);
  }
  promise(props)
    .then(({ data }) => {
      if (onSuccess) {
        onSuccess(data.data || data);
      }
      if (message) {
        infoMessage(data?.message);
      }
    })
    .catch((err) => {
      if (onError) {
        onError(errorHandler(err));
      }
      errorMessage(err?.response?.data?.message || "Xatolik yuz berdi!");
    })
    .finally(() => {
      if (loader) {
        loader(false);
      }
    });
};
