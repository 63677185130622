export const errorHandler = (err) => {
  const { errors } = err?.response?.data || {};
  if (errors) {
    const file = Object.keys(errors)?.map((error) => {
      return {
        name: [error],
        errors: [errors[error]],
      };
    });
    return file || [];
  }
  return [];
};
