import { HttpRequestHub } from '../HttpRequestHub';

export const PacketGet = () => {
  const config = {
    method: 'GET',
    url: 'reception/packets/get-list',
  };
  return HttpRequestHub(config);
};

export const PacketGetById = (id) => {
  const config = {
    method: 'GET',
    url: 'reception/packets/show/' + id,
  };
  return HttpRequestHub(config);
};

export const PacketCreate = (data) => {
  const config = {
    method: 'POST',
    url: 'reception/packets/store',
    data,
  };
  return HttpRequestHub(config);
};

export const PacketEdit = ({ data, id }) => {
  const config = {
    method: 'POST',
    url: 'reception/packets/update/' + id,
    data,
  };
  return HttpRequestHub(config);
};

export const getPacketServices = () => {
  const config = {
    method: 'GET',
    url: 'reception/packets/get-service-list',
  };
  return HttpRequestHub(config);
};
