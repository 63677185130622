import React, { useState } from 'react';
import { Form, Input, Button, Avatar, Col, Row, Typography, Space } from 'antd';
import { sendUser } from '../axios/server/config/User';
import { setCookie } from '../axios/functions/useCookies';
import { TOKEN } from '../axios/constants';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './loginpage.css';

const { Title } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      sendUser(values)
        .then((res) => {
          setCookie(TOKEN, res.data.token);
          window.location.href = '/dashboard';
        })
        .catch((err) => {
          setLoading(false);
          const { errors } = err?.response?.data || {};
          if (errors) {
            const file = Object.keys(errors)?.map((error) => {
              return {
                name: [error],
                errors: [errors[error]],
              };
            });
            form.setFields(file || []);
          }
        });
    });
  };

  return (
    <div className='essential_container_login_page'>
      <Row justify='center' align='middle' className='essential_row'>
        <div className='login_avatar_div'>
          <Avatar size={75} className='login_avatar' icon={<UserOutlined />} />
        </div>
        <Col xs={20} sm={12} md={16} lg={16}>
          <Title level={2} className='text-center login_title'>
            Saytga kirish
          </Title>

          <Form form={form} layout='vertical' name='basic'>
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите ваш логин!',
                },
              ]}
              key='username'
            >
              <Input
                autoFocus
                prefix={<UserOutlined className='site-form-item-icon' id='usericon_login' />}
                placeholder='Пожалуйста, введите ваш логин'
                className='login_input'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, введите пароль!',
                },
              ]}
              key='password'
            >
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon' id='lockicon_login' />}
                placeholder='Пожалуйста, введите пароль'
                className='login_input'
              />
            </Form.Item>
          </Form>
          <Space style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={onFinish}
              loading={loading}
              style={{
                width: '200px',
                borderRadius: '11px',
                padding: '18px 22px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '20px',
                lineHeight: '22px',
                fontWeight: '800',
                boxShadow: '2px 2px 10px rgb(120,100,100)',
                backgroundColor: '#4998af',
              }}
            >
              Вход
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
