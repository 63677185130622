import { HttpRequestHub } from '../HttpRequestHub';

export const getActiveOrderList = () => {
  const config = {
    method: 'GET',
    url: 'reception/dashboard/get-order-list',
  };
  return HttpRequestHub(config);
};

export const OrdersGet = () => {
  const config = {
    method: 'GET',
    url: 'reception/dashboard/get-list',
  };
  return HttpRequestHub(config);
};

// export const OrderGetById = (id) => {
//   const config = {
//     method: 'GET',
//     url: 'order/' + id,
//   };
//   return HttpRequestHub(config);
// };

export const OrderResponseSend = ({ data, id }) => {
  const config = {
    method: 'POST',
    url: 'reception/dashboard/store-booking-times/' + id,
    data,
  };
  return HttpRequestHub(config);
};
