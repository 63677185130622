import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Select,
  Table,
  Form,
  Modal,
  Tabs,
  Space,
  Switch,
  InputNumber,
  Tag,
  Spin,
  Progress,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { GetLanguageConfig } from "../../axios/server/config/GetLanguage";
import "./Service.css";
import {
  editService,
  getDoctorByService,
  getOneService,
  getServiceCategoryList,
  getServiceCategoryListForUpdate,
  getServiceDoctors,
  getServicesBySubCategory,
  getServiceTimeTable,
  getServiceTimeTableWeekDays,
  sendServiceTimeTableConfig,
} from "../../axios/server/config/StandartServiceConfig";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { resHandler } from "../../helper/response-handler";
import { formDataBuilder } from "../../helper/data-builder";
import { TimeTableModalBuilder } from "./TimeTableModalBuilder";
import { timesBuilder } from "../../helper/timesBuilder";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;
const { Option } = Select;

const Service = () => {
  const [lang, setLang] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [selected, setSelected] = useState(null);
  const [formSubCategories, setFormSubCategories] = useState([]);
  const [formServices, setFormServices] = useState([]);
  const [formServiceDoctors, setFormServiceDoctors] = useState([]);
  const [serviceDoctorId, setServiceDoctorId] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [defaultSubCategory, setDefaultSubCategory] = useState(null);
  const [serviceTimeTableModal, setServiceTimeTableModal] = useState(false);
  const [serviceTimeTable, setServiceTimeTable] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayServiceItemData, setDisplayServiceItemData] = useState([]);
  const [serviceAddTimeTable, setServiceAddTimeTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceDoctors, setServiceDoctors] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const [ServiceForm] = Form.useForm();

  const serviceColumns = [
    {
      title: "Servis nomi",
      dataIndex: "name",
    },
    {
      title: "Servis narxi",
      dataIndex: "price",
    },
    {
      title: "TimeTable",
      dataIndex: "id",
      render: (id) => (
        <Button onClick={() => showServiceTimeTableModal(id)}>
          Time Tableni ko'rish
        </Button>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Progress
          type="circle"
          percent={100}
          width={25}
          status={status ? "success" : "exception"}
        />
      ),
    },
  ];

  const getSubCategories = useCallback((id) => {
    resHandler({
      promise: getServiceCategoryList,
      props: id,
      loader: setLoading,
      onSuccess: (data) => {
        setAllSubCategory(data);
        if (data.length) {
          setDefaultSubCategory(data[0].id);
          getSubCategoryServices(data[0].id);
        } else {
          setDefaultSubCategory(null);
          setDisplayServiceItemData([]);
        }
      },
    });
  }, []);

  const getCategories = useCallback(() => {
    resHandler({
      promise: getServiceCategoryList,
      onSuccess: (data) => {
        setAllCategory(data);
        if (data.length) {
          getSubCategories(data[0].id);
          setDefaultCategory(data[0].id);
        }
      },
      loader: setLoading,
    });
  }, [getSubCategories]);

  useEffect(() => {
    languageGet();
    getCategories();
    getWeekDays();
  }, [getCategories]);

  const languageGet = () => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: (data) => {
        setLang(data.map((v) => v.url));
      },
    });
  };

  const getSubCategoryServices = (id) => {
    resHandler({
      promise: getServicesBySubCategory,
      props: id,
      loader: setLoading,
      onSuccess: setDisplayServiceItemData,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const formCategoryChange = (v) => {
    resHandler({
      promise: getServiceCategoryListForUpdate,
      props: { selected, id: v },
      onSuccess: setFormSubCategories,
    });
    ServiceForm.resetFields(["service_category_id", "doctors"]);
    setFormServiceDoctors([]);
    setFormServices([]);
  };

  const formSubCategoryChange = (id) => {
    resHandler({
      promise: getServiceCategoryListForUpdate,
      props: { selected, id },
      onSuccess: setFormServices,
    });
  };

  const formServiceChangeForUpdate = (id) => {
    resHandler({
      promise: getDoctorByService,
      props: id,
      onSuccess: setFormServiceDoctors,
    });
  };

  const handleCancel = () => {
    ServiceForm.resetFields();
    setIsModalVisible(false);
  };

  function handleDisplayCategory(value) {
    setDefaultCategory(value);
    getSubCategories(value);
  }

  const handleDisplaySubCategory = (value) => {
    setDefaultSubCategory(value);
    getSubCategoryServices(value);
  };

  const saveServiceItem = () => {
    ServiceForm.validateFields().then((values) => {
      const formData = formDataBuilder(values, lang);
      resHandler({
        promise: editService,
        props: { data: formData, id: selected },
        loader: setLoading,
        message: true,
        onError: ServiceForm.setFields,
        onSuccess: () => {
          setSelected(null);
          handleCancel();
          getSubCategoryServices(defaultSubCategory);
        },
      });
    });
  };

  const editServiceItem = () => {
    formCategoryChange(defaultCategory);
    formSubCategoryChange(defaultSubCategory);
    resHandler({
      promise: getOneService,
      props: selected,
      onSuccess: (data) => {
        formServiceChangeForUpdate(data.service_category_id);
        const doctors = data.doctors.map((v) => v.id);
        const status = !!data.status;
        const values = { ...data, doctors, status };
        ServiceForm.setFieldsValue(values);
      },
    });
    showModal(true);
  };

  const [weekDays, setWeekDays] = useState([]);

  const getWeekDays = () => {
    resHandler({
      promise: getServiceTimeTableWeekDays,
      onSuccess: setWeekDays,
    });
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys: selected ? [selected] : [],
    onChange: ([key]) => setSelected(key),
  };

  const resetSearch = () => {
    setSelected(null);
  };

  const getTimeTable = (props) => {
    resHandler({
      promise: getServiceTimeTable,
      props,
      loader: setSpinner,
      onSuccess: setServiceTimeTable,
    });
  };

  const showServiceTimeTableModal = (id) => {
    setServiceTimeTableModal(true);
    getServiceDoctorList(id);
  };

  const showServiceTimeTable = () => {
    setServiceAddTimeTable(true);
    setServiceTimeTableModal(false);
  };

  const closeShowServiceTimeTable = () => {
    setServiceAddTimeTable(false);
  };
  const closeServiceTimeTable = () => {
    setServiceTimeTableModal(false);
    setServiceTimeTable(null);
    setServiceDoctors([]);
    closeEditMode();
  };

  const sendServiceTimeTableData = (times) => {
    const formData = new FormData();
    formData.append("doctor_id", serviceDoctorId);
    formData.append("service_id", serviceId);
    let index = 0;
    times.forEach((day) => {
      if (day.service_times.length) {
        day.service_times.forEach((time) => {
          formData.append(`times[${index}][time]`, time.time.slice(0, 5));
          formData.append(`times[${index}][weekday_id]`, day.weekday_id);
          index = index + 1;
        });
      }
    });

    resHandler({
      promise: sendServiceTimeTableConfig,
      props: formData,
      onSuccess: () => {
        closeServiceTimeTable();
        closeShowServiceTimeTable();
      },
      message: true,
    });
  };

  const getServiceDoctorList = (serviceId) => {
    setServiceId(serviceId);
    resHandler({
      promise: getServiceDoctors,
      props: serviceId,
      loader: setSpinner,
      onSuccess: (data) => {
        setServiceDoctors(data);
        if (data.length) {
          setServiceDoctorId(data[0].id);
          getTimeTable({ serviceId, doctorId: data[0].id });
        }
      },
    });
  };

  const serviceDoctorChange = (doctorId) => {
    setServiceDoctorId(doctorId);
    getTimeTable({ serviceId, doctorId });
  };

  const editTimeTable = () => {
    setEditMode(true);
  };

  const closeEditMode = () => {
    setEditMode(false);
  };

  const timeClick = ({ target }) => {
    if (editMode) {
      const {
        dataset: { time, day, color },
      } = target;
      if (time && color) {
        const table = { ...serviceTimeTable };
        table.service_weekdays.forEach((weekDay) => {
          if (weekDay.weekday_id.toString() === day) {
            if (color === "default") {
              weekDay.service_times.push({ time, service_id: "1" });
            } else {
              weekDay.service_times = weekDay.service_times.filter(
                (v) => v.time.slice(0, 5) !== time.slice(0, 5)
              );
            }
          }
        });
        setServiceTimeTable(table);
      }
    }
  };

  const saveEdited = () => {
    sendServiceTimeTableData(serviceTimeTable.service_weekdays);
  };

  return (
    <div>
      <Layout style={{ backgroundColor: "white" }}>
        <Space>
          <b>Kategoriya:</b>
          <Select
            style={{ width: 200 }}
            placeholder="Kategoriyani tanlang"
            onChange={handleDisplayCategory}
            value={defaultCategory}
          >
            {allCategory.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
          <b>Subkategoriya:</b>

          <Select
            style={{ width: 200 }}
            placeholder="Standart servis subkategoriyasini tanlang"
            value={defaultSubCategory}
            onChange={handleDisplaySubCategory}
          >
            {allSubCategory.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Space>
      </Layout>

      <Table
        rowSelection={rowSelection}
        columns={serviceColumns}
        dataSource={displayServiceItemData}
        rowKey="id"
        loading={loading}
        title={() => (
          <Space wrap>
            {selected !== null ? (
              <>
                <Button onClick={editServiceItem} icon={<EditOutlined />}>
                  Servisni tahrirlash
                </Button>
                <Button
                  shape="round"
                  icon={<CloseOutlined />}
                  onClick={resetSearch}
                >
                  Yopish
                </Button>
              </>
            ) : (
              <Button type="primary">
                <Link to="/service/add">Yangi service qo'shish</Link>
              </Button>
            )}
          </Space>
        )}
      />

      <TimeTableModalBuilder
        weekDays={weekDays}
        isShow={serviceAddTimeTable}
        onOk={sendServiceTimeTableData}
        onCancel={closeShowServiceTimeTable}
        serviceDoctors={serviceDoctors}
        serviceDoctorId={serviceDoctorId}
        serviceDoctorChange={serviceDoctorChange}
      />

      <Modal
        title="Servis malumotlarini tahrirlash"
        visible={isModalVisible}
        centered
        width="70%"
        onOk={saveServiceItem}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          form={ServiceForm}
          initialValues={{ status: true }}
        >
          <Form.Item label="Servis kategoriyasi:">
            <Select
              disabled
              defaultValue={defaultCategory}
              placeholder="Kategoriyani tanlang"
            >
              {allCategory.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Servis subkategoriyasi:">
            <Select
              disabled
              defaultValue={defaultSubCategory}
              placeholder="Subkategoriyani tanlang"
            >
              {formSubCategories.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="service_category_id" label="Servisni tanlang">
            <Select disabled placeholder="Servisni tanlang">
              {formServices.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Doktorni tanlang:"
            key="Doktorni tanlang:"
            name="doctors"
            rules={[
              {
                required: true,
                message: "Please input your username!",
                type: "array",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Servis doktorini tanlang">
              {formServiceDoctors.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.user?.fio}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Servis narxi:"
            key="Servis narxi:"
            name="price"
            rules={[
              {
                required: true,
                message: "Servis narxini kiriting!",
                type: "number",
              },
            ]}
          >
            <InputNumber style={{ width: 200 }} placeholder="1000" />
          </Form.Item>
          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <Space>
            <b>Service time table haqida ma'lumot</b>
            {serviceTimeTable ? (
              <>
                {editMode ? (
                  <Button icon={<CloseOutlined />} onClick={closeEditMode}>
                    Tahrirlashni yopish
                  </Button>
                ) : (
                  <Button
                    icon={<EditOutlined />}
                    onClick={editTimeTable}
                    type="primary"
                  >
                    Tahrirlash
                  </Button>
                )}
              </>
            ) : null}
          </Space>
        }
        visible={serviceTimeTableModal}
        centered
        width="70%"
        onCancel={closeServiceTimeTable}
        okButtonProps={{ disabled: !editMode }}
        okText="Saqlash"
        onOk={saveEdited}
      >
        <Tabs
          defaultActiveKey="0"
          centered
          tabBarExtraContent={
            <Select
              placeholder="doktorni tanlang"
              value={serviceDoctorId}
              onChange={serviceDoctorChange}
            >
              {serviceDoctors.map((doctor) => (
                <Option key={doctor.id} value={doctor.id}>
                  {doctor.user.fio}
                </Option>
              ))}
            </Select>
          }
        >
          {serviceTimeTable ? (
            serviceTimeTable?.service_weekdays.map((day, i) => (
              <TabPane
                tab={weekDays.find((v) => v.id === day.weekday_id).name}
                key={i}
              >
                <Spin spinning={spinner}>
                  <Space wrap style={{ padding: "1rem" }} onClick={timeClick}>
                    {timesBuilder(day, serviceTimeTable).map((time, index) => (
                      <>
                        {time.doctor_time_table_id !== undefined ? (
                          <Tag
                            style={{
                              padding: "0.3rem 0.5rem",
                              fontSize: "1rem",
                            }}
                            key={index}
                            color="red"
                          >
                            {time.time.slice(0, 5)}
                          </Tag>
                        ) : (
                          <Tag
                            data-day={day.weekday_id}
                            data-time={time.time}
                            data-color={
                              time.service_id === undefined
                                ? "default"
                                : "green"
                            }
                            style={{
                              padding: "0.3rem 0.5rem",
                              fontSize: "1rem",
                              cursor: editMode ? "pointer" : "default",
                            }}
                            key={index}
                            color={
                              time.service_id === undefined
                                ? "default"
                                : "green"
                            }
                          >
                            {time.time.slice(0, 5)}
                          </Tag>
                        )}
                      </>
                    ))}
                  </Space>
                </Spin>
              </TabPane>
            ))
          ) : (
            <TabPane tab="">
              <Spin spinning={spinner}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3>Service time table mavjud emas</h3>
                  <Button type="primary" onClick={showServiceTimeTable}>
                    Time Table qo'shish
                  </Button>
                </div>
              </Spin>
            </TabPane>
          )}
        </Tabs>
      </Modal>
    </div>
  );
};

export default Service;
