import React, { useEffect, useState } from "react";
import "./PacketCategory.css";
import { Table, Button, Modal, Form, Input, Space, Switch } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { GetLanguageConfig } from "../../axios/server/config/GetLanguage";
import {
  PacketCategoryEdit,
  PacketCategoryGet,
  PacketCategoryGetById,
  PacketCategorySend,
} from "../../axios/server/config/PacketCategoryCrud";
import { resHandler } from "../../helper/response-handler";

const PacketCategory = () => {
  const [languages, setLanguages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [packetCategoryList, setPacketCategoryList] = useState([]);

  const [formPacketCategory] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: (data) => setLanguages(data.map((v) => v.url)),
    });
    getPacketCategories();
  }, []);

  const columns = [
    {
      title: "Packet kategoriya nomi",
      dataIndex: "name",
    },
  ];

  const getPacketCategories = () => {
    resHandler({
      promise: PacketCategoryGet,
      loader: setLoading,
      onSuccess: setPacketCategoryList,
    });
  };

  const close = () => {
    setSelected(null);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys: selected ? [selected] : [],
    onChange: ([value]) => setSelected(value),
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const savePackets = () => {
    formPacketCategory.validateFields().then((values) => {
      if (selected !== null) {
        resHandler({
          promise: PacketCategoryEdit,
          loader: setLoading,
          onSuccess: () => {
            getPacketCategories();
            handleCancel();
          },
          onError: formPacketCategory.setFields,
          message: true,
          props: { id: selected, data: values },
        });
      } else {
        resHandler({
          promise: PacketCategorySend,
          loader: setLoading,
          onSuccess: () => {
            getPacketCategories();
            handleCancel();
          },
          onError: formPacketCategory.setFields,
          message: true,
          props: values,
        });
      }
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const editPacketCategory = () => {
    resHandler({
      promise: PacketCategoryGetById,
      props: selected,
      onSuccess: formPacketCategory.setFieldsValue,
      loader: setLoading,
    });
    showModal();
  };

  return (
    <div className="illness client_section">
      <div className="tool-header" style={{ marginBottom: "20px" }}>
        <h1>Shifoxona servise packet kategoriyalari:</h1>
      </div>
      <div>
        <Table
          title={() => (
            <Space wrap>
              {selected ? (
                <>
                  <Button
                    style={{ color: "green" }}
                    onClick={editPacketCategory}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    onClick={close}
                    shape="round"
                    icon={<CloseOutlined />}
                  >
                    Yopish
                  </Button>
                </>
              ) : (
                <Button type="primary" onClick={showModal}>
                  Packet Categoriya Qo'shish
                </Button>
              )}
            </Space>
          )}
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={packetCategoryList}
          pagination={false}
          rowKey="id"
        />
      </div>

      <Modal
        title="Packet kategoriya qoshish"
        visible={isModalVisible}
        centered
        width="70%"
        okText="Saqlash"
        cancelText="Bekor qilish"
        onOk={savePackets}
        onCancel={handleCancel}
        className="illness-modal z-modal"
      >
        <Form
          layout="vertical"
          form={formPacketCategory}
          initialValues={{ status: true }}
        >
          {languages.map((item, index) => (
            <Form.Item
              label={`Packet kategoriyani ${item}da kiriting`}
              name={["name", item]}
              key={index}
              rules={[{ required: true, message: "Toldirilmagan" }]}
            >
              <Input />
            </Form.Item>
          ))}
          <Form.Item label="Status" name="status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PacketCategory;
