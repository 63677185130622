import { HttpRequestHub } from "../HttpRequestHub";

export const getServiceCategoryList = (id = "") => {
  const config = {
    method: "GET",
    url: "reception/services/service-category-list?category_id=" + id,
  };
  return HttpRequestHub(config);
};

export const getServiceCategoryListForUpdate = ({ selected, id = "" }) => {
  const config = {
    method: "GET",
    url: `reception/services/service-category-list/${selected}/for-update-service?category_id=${id}`,
  };
  return HttpRequestHub(config);
};

export const getServicesBySubCategory = (id) => {
  const config = {
    method: "GET",
    url: "reception/services/list?subcategory=" + id,
  };
  return HttpRequestHub(config);
};

export const getDoctorByService = (id) => {
  const config = {
    method: "GET",
    url: "reception/services/doctor-list?standard_service=" + id,
  };
  return HttpRequestHub(config);
};

export const getOneService = (id) => {
  const config = {
    method: "GET",
    url: "reception/services/show/" + id,
  };
  return HttpRequestHub(config);
};

export const createService = (data) => {
  const config = {
    method: "POST",
    url: "reception/services/store",
    data,
  };
  return HttpRequestHub(config);
};

export const editService = ({ data, id }) => {
  const config = {
    method: "POST",
    url: "reception/services/update/" + id,
    data,
  };
  return HttpRequestHub(config);
};

// ###############################################

export const getServiceTimeTable = ({ doctorId, serviceId }) => {
  const config = {
    method: "GET",
    url: `reception/services/time-table/show?doctor_id=${doctorId}&service_id=${serviceId}`,
  };
  return HttpRequestHub(config);
};

export const sendServiceTimeTableConfig = (data) => {
  const config = {
    method: "POST",
    url: "reception/services/time-table/store",
    data,
  };
  return HttpRequestHub(config);
};

export const getServiceTimeTableWeekDays = () => {
  const config = {
    method: "GET",
    url: "reception/services/time-table/weekdays",
  };
  return HttpRequestHub(config);
};

export const getServiceDoctors = (id) => {
  const config = {
    method: "GET",
    url: "reception/services/time-table/get-doctors/" + id,
  };
  return HttpRequestHub(config);
};
