import React, { createContext, useState } from 'react';

export const ControlContext = createContext(null);

export const ControlContextProvider = (props) => {
  const [controls, setControls] = useState({
    timetable: true,
    billing: false,
    medkarta: true,
  });
  return <ControlContext.Provider value={[controls, setControls]}>{props.children}</ControlContext.Provider>;
};
