export const findTime = (time, interval) => {
  let next;
  let minutes = Number(time?.slice(0, 2)) * 60 + Number(time?.slice(3, 5));
  minutes += interval;
  let hour = parseInt(minutes / 60);
  let min = minutes % 60;

  if (hour < 10) {
    next = "0" + hour + ":";
  } else {
    next = hour + ":";
  }
  if (min < 10) {
    next += "0" + min;
  } else {
    next += min;
  }
  return next;
};

export const findTimeArray = ({ start, end, interval }) => {
  const timeArray = [];
  while (start < end) {
    timeArray.push(start);
    start = findTime(start, interval);
  }
  return timeArray;
};
