import React, { useState } from "react";
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Space,
  Tabs,
  Tag,
  TimePicker,
} from "antd";
import { findTimeArray } from "../../helper/findTime";

const format = "HH:mm";
const { TabPane } = Tabs;

export const DoctorTimeTableBuilder = ({
  isShow,
  onOk,
  onCancel,
  weekDays,
  setTimeTableValues,
}) => {
  const [generated, setGenerated] = useState([]);
  const [times, setTimes] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      start: fieldsValue["start"].format(format),
      end: fieldsValue["end"].format(format),
    };
    setTimes([]);
    generateTimeTables(values);
    setTimeTableValues(values);
  };

  const generateTimeTables = ({ start, end, interval }) => {
    if (start && end && interval) {
      const timeArray = findTimeArray({ start, end, interval });
      setGenerated(timeArray);
    }
  };

  const cancel = () => {
    setGenerated([]);
    setTimes([]);
    onCancel();
    form.resetFields();
  };

  const timeClick = ({ target }) => {
    const {
      dataset: { time, day },
    } = target;
    if (time) {
      let days = times;
      let arr;
      if (!times.length) {
        days = weekDays.map(({ id }) => ({ weekday_id: id, busy_times: [] }));
        setTimes(days);
      }
      if (
        days
          .find((v) => v.weekday_id.toString() === day)
          ?.busy_times.find((v) => v.time === time)
      ) {
        arr = times.map((weekDay) => {
          if (weekDay.weekday_id.toString() === day) {
            weekDay.busy_times = weekDay.busy_times.filter(
              (v) => v.time !== time
            );
          }
          return weekDay;
        });
      } else {
        arr = days.map((weekDay) => {
          return weekDay.weekday_id.toString() === day
            ? { ...weekDay, busy_times: [...weekDay.busy_times, { time }] }
            : weekDay;
        });
      }
      setTimes(arr);
    }
  };

  const ok = () => {
    setGenerated([]);
    setTimes([]);
    onOk(times);
    form.resetFields();
  };

  return (
    <Modal
      title="Doktor time tableni yaratish"
      visible={isShow}
      centered
      width="70%"
      onOk={ok}
      onCancel={cancel}
      okText="Saqlash"
    >
      <div style={{ marginBottom: "1rem" }}>
        <Form form={form} onFinish={onFinish} layout="inline">
          <Form.Item name="start" rules={[{ required: true, type: "object" }]}>
            <TimePicker format={format} placeholder="Start time" />
          </Form.Item>
          <Form.Item name="end" rules={[{ required: true, type: "object" }]}>
            <TimePicker format={format} placeholder="End time" />
          </Form.Item>
          <Form.Item
            name="interval"
            rules={[{ required: true, type: "number" }]}
          >
            <InputNumber placeholder="Interval" />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Generate Times
          </Button>
        </Form>
      </div>
      <Tabs defaultActiveKey="0" centered>
        {weekDays.length ? (
          weekDays.map((day, i) => (
            <TabPane tab={day.name} key={i}>
              <div>
                <Space wrap style={{ padding: "1rem 0" }} onClick={timeClick}>
                  {generated.map((time) => (
                    <Tag
                      data-day={day.id}
                      data-time={time}
                      style={{
                        padding: "0.3rem 0.5rem",
                        fontSize: "1rem",
                        cursor: "pointer",
                      }}
                      key={time}
                      color={
                        times
                          .find((v) => v.weekday_id === day.id)
                          ?.busy_times.find((v) => v.time?.slice(0, 5) === time)
                          ? "red"
                          : "default"
                      }
                    >
                      {time}
                    </Tag>
                  ))}
                </Space>
              </div>
            </TabPane>
          ))
        ) : (
          <b>Hafta kunlari mavjud emas</b>
        )}
      </Tabs>
    </Modal>
  );
};
