import { findTime } from "./findTime";

export const timesBuilder = (day, data) => {
  const haveTimes = day.busy_times.concat(
    day.service_times || day.services_times || []
  );
  const timeArray = [];
  const { start_time, end_time, interval } = data;
  if (start_time && end_time && interval && interval > 0) {
    let start = start_time.slice(0, 5);
    const end = end_time.slice(0, 5);
    while (start && end && start < end) {
      const st = start;
      const isHave = haveTimes.find((v) => v.time.slice(0, 5) === st);
      if (isHave) {
        timeArray.push(isHave);
      } else {
        timeArray.push({ time: start });
      }
      start = findTime(start, interval);
    }
  }
  return timeArray;
};
