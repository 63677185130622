export const fileBuilder = ({ values, key, single = true, fileKey }) => {
  if (single) {
    const url = values[key];
    const name = url?.slice(-20) || "image";
    return {
      uid: name,
      name,
      status: "done",
      thumbUrl: url,
      url,
      isBuild: true,
      id: url?.id,
    };
  }
  return values[key].map((file) => {
    const url = file[fileKey];
    const name = url?.slice(-20) || "image";
    return {
      uid: name,
      name,
      status: "done",
      thumbUrl: url,
      url,
      isBuild: true,
      id: file?.id,
    };
  });
};
