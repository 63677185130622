export const formDataBuilder = (data, languages) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array) {
      data[key].forEach((val) => {
        formData.append(`${key}[]`, val);
      });
    } else if (data[key] instanceof Object && data[key][languages[0]]) {
      languages.forEach((lang) => {
        formData.append(`${key}[${lang}]`, data[key][lang]);
      });
    } else if (typeof data[key] === "boolean") {
      formData.append(key, data[key] ? "1" : "0");
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};
