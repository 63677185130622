import React, { useEffect, useState } from 'react';
import './Packet.css';
import { Button, Input, Table, Upload, Modal, Select, Form, Switch, Space } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { GetLanguageConfig } from '../../axios/server/config/GetLanguage';
import {
  getPacketServices,
  PacketCreate,
  PacketEdit,
  PacketGet,
  PacketGetById,
} from '../../axios/server/config/PacketCrud';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PacketCategoryGet } from '../../axios/server/config/PacketCategoryCrud';
import { resHandler } from '../../helper/response-handler';
import { formDataBuilder } from '../../helper/data-builder';
import { fileBuilder } from '../../helper/file-builder';
import ReactHtmlParser from 'react-html-parser';

const { Option } = Select;

const Packet = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [packetList, setPacketList] = useState([]);
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState(null);
  const [ckData, setCkData] = useState({ uz: '<p></p>', ru: '<p></p>' });
  const [modalShow, setModalShow] = useState(false);
  const [formPacket] = Form.useForm();
  const [packetCategoryList, setPacketCategoryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [packetModal, setPacketModal] = useState(false);
  const [readList, setRead] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    languageGet();
    getPacket();
    getPacketCategories();
    getServices();
  }, []);

  const languageGet = () => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: (data) => setLanguages(data.map((v) => v.url)),
    });
  };

  const getPacket = () => {
    resHandler({
      promise: PacketGet,
      loader: setLoading,
      onSuccess: setPacketList,
    });
  };

  const getPacketCategories = () => {
    resHandler({
      promise: PacketCategoryGet,
      loader: setLoading,
      onSuccess: setPacketCategoryList,
    });
  };

  const getServices = () => {
    resHandler({
      promise: getPacketServices,
      onSuccess: setServiceList,
    });
  };

  // Modal functions
  const showModal = () => {
    setPacketModal(true);
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: selected !== null ? [selected] : [],
    onChange: ([key]) => setSelected(key),
  };

  const columns = [
    {
      title: 'Nomi',
      dataIndex: 'name',
    },
    {
      title: 'Narxi',
      dataIndex: 'price',
    },
    {
      title: "Ma'lumotlari",
      render: (item) => (
        <Button
          onClick={() => {
            readMore(item);
          }}
        >
          Batafsil
        </Button>
      ),
    },
  ];

  const readMore = (item) => {
    setRead(item);
    setModalShow(true);
  };

  const handleChange = (info) => {
    setImage(info.file);
    setFileList(info.fileList);
  };
  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.thumbUrl);
  };
  const deleteImg = () => {
    setFileList([]);
  };

  const getEditPacket = () => {
    setPacketModal(true);
    resHandler({
      promise: PacketGetById,
      props: selected,
      onSuccess: (data) => {
        const services = data.services.map((v) => v.id);
        const file = fileBuilder({ values: data, key: 'image' });
        setFileList([file]);
        setCkData(data.description);
        formPacket.setFieldsValue({ ...data, services });
      },
    });
  };

  const savePacket = () => {
    formPacket.validateFields().then((values) => {
      const formData = formDataBuilder(values, languages);
      if (image) {
        formData.append('image', image);
      }
      if (selected !== null) {
        resHandler({
          promise: PacketEdit,
          props: { id: selected, data: formData },
          loader: setLoading,
          onSuccess: () => {
            getPacket();
            cancelPacket();
          },
          onError: formPacket.setFields,
          message: true,
        });
      } else {
        resHandler({
          promise: PacketCreate,
          props: formData,
          loader: setLoading,
          onSuccess: () => {
            getPacket();
            cancelPacket();
          },
          onError: formPacket.setFields,
          message: true,
        });
      }
    });
  };

  const cancelPacket = () => {
    setPacketModal(false);
    formPacket.resetFields();
    setImage(null);
    setCkData({ uz: '<p></p>', ru: '<p></p>' });
  };

  return (
    <>
      <div className='packet-section'>
        <div className='tool-header'>
          <h1>Tibbiy muassasaning service packetlari:</h1>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={packetList}
          rowKey='id'
          bordered
          pagination={{
            hideOnSinglePage: true,
          }}
          loading={loading}
          title={() => (
            <Space wrap>
              {selected === null ? (
                <Button onClick={showModal}>Yangi service packet qo'shish</Button>
              ) : (
                <>
                  <Button icon={<EditOutlined />} onClick={getEditPacket} />
                  <Button icon={<CloseOutlined />} shape='round' onClick={() => setSelected(null)}>
                    Yopish
                  </Button>
                </>
              )}
            </Space>
          )}
        />
      </div>

      <Modal
        width={1000}
        title={selected !== null ? "Packet Ma'lumotlarini tahrirlash" : "Packet Ma'lumotlarini kiriting"}
        centered
        visible={packetModal}
        onOk={savePacket}
        onCancel={cancelPacket}
        okText='Saqlash'
      >
        <Form layout='vertical' form={formPacket} initialValues={{ status: true }}>
          {languages.map((item) => {
            return (
              <div key={item}>
                <Form.Item
                  name={['name', item]}
                  label={`Packet Nomini Kiriting (${item})`}
                  rules={[{ required: true, message: "To'ldirilmagan" }]}
                >
                  <Input size='large' placeholder={`Packetni ${item} nomi...`} />
                </Form.Item>
                <Form.Item
                  label={`Packet haqida ma'lumotlarni ${item}da kiriting:`}
                  name={['description', item]}
                  initialValue={ckData[item]}
                  getValueFromEvent={(_, editor) => editor.getData()}
                  rules={[{ required: true, message: "To'ldirilmagan" }]}
                >
                  <CKEditor data={ckData[item]} editor={ClassicEditor} />
                </Form.Item>
              </div>
            );
          })}

          <Form.Item
            label={`Packet Categoriyasini tanlang `}
            name='packet_category_id'
            rules={[{ required: true, message: "To'ldirilmagan" }]}
          >
            <Select style={{ width: '100%' }}>
              {packetCategoryList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={`Packet Servicelarini tanlang `}
            name='services'
            rules={[{ required: true, message: "To'ldirilmagan" }]}
          >
            <Select mode='multiple' style={{ width: '100%' }}>
              {serviceList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Packet narxini kiriting (so'mda)"
            name='price'
            rules={[{ required: true, message: "To'ldirilmagan" }]}
          >
            <Input size='large' />
          </Form.Item>

          <Form.Item label='Status' name='status' valuePropName='checked'>
            <Switch />
          </Form.Item>

          <Form.Item label='Rasm:' key='Rasmpacket' className='image-upload'>
            <Upload
              fileList={fileList}
              listType='picture-card'
              onPreview={(file) => handlePreview(file)}
              onChange={handleChange}
              onRemove={deleteImg}
              beforeUpload={() => false}
              maxCount={1}
              accept='.jpg, .jpeg, .png'
            >
              <div>
                <div className='ant-upload-text'>Rasmni yuklang</div>
              </div>
            </Upload>
            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={() => setPreviewVisible(false)}
              cancelText='Yopish'
            >
              <img alt='example' style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={800}
        title="Packet Ma'lumotlari"
        centered
        visible={modalShow}
        onOk={() => setModalShow(false)}
        onCancel={() => setModalShow(false)}
      >
        <Space>
          <div>
            <img width={400} src={readList.image} alt='img' />
          </div>
          <div>
            <h3>{ReactHtmlParser(readList.description)}</h3>
          </div>
        </Space>
      </Modal>
    </>
  );
};

export default Packet;
