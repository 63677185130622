import React, { useEffect } from "react";
import "./App.css";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Service from "./components/service/Service";
import Info from "./components/Info/Info";
import Tool from "./components/Tools/Tool";
import Client from "./components/Clients/Client";
import Dashboard from "./components/Dashboard/Dashboard";
import Packet from "./components/Packets/Packet";
import { SITE_LANG, TOKEN } from "./axios/constants";
import { getCookie } from "./axios/functions/useCookies";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import PacketCategory from "./components/PacketCategory/PacketCategory";
import { DoctorPage } from "./components/doctors/DoctorPage";
import { ServiceBuilder } from "./components/service/ServiceBuilder";

function App() {
  const token = getCookie(TOKEN);
  useEffect(() => {
    const lan = localStorage.getItem(SITE_LANG);
    if (lan) {
      localStorage.setItem(SITE_LANG, lan);
    } else {
      localStorage.setItem(SITE_LANG, "uz");
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/login" exact component={Login} />
          <Layout>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/service" exact component={Service} />
            <Route path="/service/add" exact component={ServiceBuilder} />
            <Route path="/doctors" exact component={DoctorPage} />
            <Route path="/info" exact component={Info} />
            <Route path="/tool" exact component={Tool} />
            <Route path="/client" exact component={Client} />
            <Route path="/packetCategory" exact component={PacketCategory} />
            <Route path="/packet" exact component={Packet} />
            <Route
              path="/"
              exact
              render={() =>
                token ? <Redirect to="/dashboard" /> : <Redirect to="/login" />
              }
            />
          </Layout>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
