import { HttpRequestHub } from '../HttpRequestHub';

export const ToolGet = () => {
  const config = {
    method: 'GET',
    url: 'reception/technology/list',
  };
  return HttpRequestHub(config);
};

export const ToolGetById = (id) => {
  const config = {
    method: 'GET',
    url: 'reception/technology/show/' + id,
  };
  return HttpRequestHub(config);
};

export const ToolCreate = (data) => {
  const config = {
    method: 'POST',
    url: 'reception/technology/store',
    data,
  };
  return HttpRequestHub(config);
};

export const ToolEdit = ({ data, id }) => {
  const config = {
    method: 'POST',
    url: 'reception/technology/update/' + id,
    data,
  };
  return HttpRequestHub(config);
};
