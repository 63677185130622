import axios from 'axios';
import { API_URL, SITE_LANG, TOKEN } from '../constants';
import { deleteCookie, getCookie } from '../functions/useCookies';

export const token = getCookie(TOKEN);

export let host = API_URL;

export let headers = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
  Authorization: token ? `Bearer ${token}` : '',
  Language: localStorage.getItem(SITE_LANG),
};

export const axiosInstance = axios.create({
  baseURL: `${host}/`,
  headers,
  timeout: 100000,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403 || error.response.status === 401) {
        deleteCookie(TOKEN);
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);
