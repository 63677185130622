import axios from 'axios';
import { API_LOGIN } from '../../constants';
import { HttpRequestHub } from '../HttpRequestHub';

const sendUser = (data) => {
  return axios.post(API_LOGIN + 'reception/login', data);
};

const logoutUser = () => {
  const config = {
    method: 'POST',
    url: 'reception/logout',
  };
  return HttpRequestHub(config);
};

export { sendUser, logoutUser };
