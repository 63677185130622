import React, { useEffect, useState } from 'react';
import './Tool.css';
import { Button, Input, Table, Upload, Form, Modal, Select, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser from 'react-html-parser';
import { GetLanguageConfig } from '../../axios/server/config/GetLanguage';
import { ToolCreate, ToolEdit, ToolGet, ToolGetById } from '../../axios/server/config/ToolCrud';
import { errorMessage } from '../../Notifications/message';
import { formDataBuilder } from '../../helper/data-builder';
import { resHandler } from '../../helper/response-handler';

const Tool = () => {
  const [languages, setLanguages] = useState([]);
  const [lang, setLang] = useState('uz');
  const [formTool] = Form.useForm();
  const [modalVisible, setModal] = useState(false);
  const [readList, setRead] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editId, setEditid] = useState(null);
  const [toolList, setTools] = useState([]);
  const [ckData, setCkData] = useState({ uz: '', ru: '' });
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [toolModal, setToolmodal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const resetSearch = () => {
    setEditid(null);
    getTools();
    setSelectedRowKeys([]);
  };

  const onSelectChange = (selectedRowKeys) => {
    setEditid(selectedRowKeys[0]);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Nomi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Ma'lumotlari",
      render: (item) => (
        <Button
          onClick={() => {
            readMore(item);
          }}
        >
          Ko'rish
        </Button>
      ),
      key: 'tollInfo',
    },
  ];

  const readMore = (item) => {
    resHandler({
      promise: ToolGetById,
      props: item.id,
      onSuccess: setRead,
    });
    setModal(true);
  };

  const showModal = () => {
    setToolmodal(true);
  };

  const handleChange = (info) => {
    setImage(info.file);
    if (info.file.status === 'removed') {
      setImage(null);
    }
    setFileList(info.fileList);
  };

  const handlePreview = (file) => {
    setPreviewVisible(true);
    setPreviewImage(file.thumbUrl);
  };

  const getTools = () => {
    resHandler({
      promise: ToolGet,
      loader: setLoading,
      onSuccess: setTools,
    });
  };

  const editGetTool = () => {
    setToolmodal(true);
    ToolGetById(editId)
      .then(({ data }) => {
        formTool.setFieldsValue(data);
        setCkData(data.description);
        setPreviewImage(data?.image);

        const link = data?.image;
        const file = {
          uid: link,
          name: 'image',
          status: 'done',
          thumbUrl: link,
          url: link,
        };
        setFileList([file]);
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
      });
  };

  const saveTools = () => {
    formTool.validateFields().then((values) => {
      const formData = formDataBuilder(values, languages);
      if (image) {
        formData.append('image', image);
      }
      if (editId !== null) {
        resHandler({
          promise: ToolEdit,
          props: { data: formData, id: editId },
          loader: setLoading,
          onSuccess: () => {
            getTools();
            setSelectedRowKeys([]);
            cancelTools();
          },
          onError: formTool.setFields,
          message: true,
        });
      } else {
        resHandler({
          promise: ToolCreate,
          props: formData,
          loader: setLoading,
          onSuccess: () => {
            getTools();
            cancelTools();
          },
          onError: formTool.setFields,
          message: true,
        });
      }
    });
  };

  const cancelTools = () => {
    setToolmodal(false);
    setEditid(null);
    setImage(null);
    setCkData({ uz: '', ru: '' });
  };

  useEffect(() => {
    resHandler({
      promise: GetLanguageConfig,
      onSuccess: (data) => setLanguages(data.map((el) => el.url)),
    });
    getTools();
  }, []);

  return (
    <div>
      <div className='toll-section'>
        <div className='tool-content'>
          <div className='tool-header'>
            <h1>Tibbiy muassasaning uskunalari:</h1>
          </div>

          <Table
            rowSelection={{ type: 'radio', ...rowSelection }}
            columns={columns}
            dataSource={toolList}
            rowKey='id'
            bordered
            loading={loading}
            title={() => (
              <div>
                <Button onClick={showModal} className={selectedRowKeys.length === 0 ? '' : 'display-none'}>
                  Yangi uskuna qo'shish
                </Button>

                <Button
                  className={selectedRowKeys.length === 1 ? '' : 'display-none'}
                  style={{ marginLeft: 16, color: 'red' }}
                  onClick={() => {
                    setEditid(null);
                    setSelectedRowKeys([]);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className={selectedRowKeys.length === 1 ? '' : 'display-none'}
                  style={{ marginLeft: 16, color: 'green' }}
                  onClick={editGetTool}
                >
                  <EditOutlined />
                </Button>

                <Button
                  style={{ marginLeft: '20px' }}
                  onClick={resetSearch}
                  disabled={!!editId}
                  type='primary'
                >
                  Reset
                </Button>
              </div>
            )}
          />
        </div>
      </div>

      <Modal
        onCancel={() => setModal(false)}
        width={1000}
        title={
          <Space>
            <h4>"Jihoz haqidagi ma'lumotlar"</h4>
            <Select defaultValue={lang} onChange={setLang}>
              {languages.map((v) => (
                <Select.Option key={v} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </Space>
        }
        centered
        visible={modalVisible}
        footer={null}
      >
        <div style={{ minHeight: '40vh', display: 'flex' }}>
          <div style={{ width: '50%', paddingRight: '30px' }}>
            <img src={readList?.image} alt='error img' style={{ width: '100%' }} />
          </div>
          <div style={{ width: '50%' }}>
            <h3>{ReactHtmlParser(readList?.description[lang])}</h3>
          </div>
        </div>
      </Modal>

      <Modal
        title={editId ? "Jihoz Ma'lumotlarini tahrirlash" : "Jihoz Ma'lumotlarini kiriting"}
        centered
        visible={toolModal}
        onOk={saveTools}
        onCancel={cancelTools}
        okText='Saqlash'
        cancelText='Bekor Qilish'
      >
        <Form form={formTool} layout='vertical'>
          {languages.map((item, index) => (
            <div key={index}>
              <Form.Item
                name={['name', item]}
                label={`Jihozlar Nomini Kiriting (${item})`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
              >
                <Input size='large' placeholder={`Jihozni ${item} dagi nomi...`} />
              </Form.Item>
              <Form.Item
                label={`Jihoz haqida ma'lumotlarni ${item} da kiriting:`}
                name={['description', item]}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
                getValueFromEvent={(_, editor) => {
                  return editor.getData();
                }}
              >
                <CKEditor data={ckData[item]} editor={ClassicEditor} />
              </Form.Item>
            </div>
          ))}

          <Form.Item label='Rasm:' key='Rasm:' className='image-upload'>
            <Upload
              fileList={fileList}
              listType='picture-card'
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={() => false}
              maxCount={1}
              accept='.jpg,.jpeg, .png, .svg, .gif'
            >
              {fileList.length ? null : <div className='ant-upload-text'>Rasmni yuklang</div>}
            </Upload>
          </Form.Item>

          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
            cancelText='Yopish'
          >
            <img alt='example' style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Form>
      </Modal>
    </div>
  );
};

export default Tool;
