import { HttpRequestHub } from '../HttpRequestHub';

export const OrderGetByStatus = (status) => {
  const query = status ? `?status=${status}` : '';
  const config = {
    method: 'GET',
    url: 'reception/clients/get-list' + query,
  };
  return HttpRequestHub(config);
};

export const ConfirmUser = (data) => {
  const config = {
    method: 'POST',
    url: 'reception/clients/confirm-order',
    data,
  };
  return HttpRequestHub(config);
};
