import { HttpRequestHub } from '../HttpRequestHub';

export const PacketCategoryGet = () => {
  const config = {
    method: 'GET',
    url: 'reception/packet-category/get-list',
  };
  return HttpRequestHub(config);
};

export const PacketCategoryGetById = (id) => {
  const config = {
    method: 'GET',
    url: 'reception/packet-category/show/' + id,
  };
  return HttpRequestHub(config);
};

export const PacketCategorySend = (data) => {
  const config = {
    method: 'POST',
    url: 'reception/packet-category/store',
    data,
  };
  return HttpRequestHub(config);
};

export const PacketCategoryEdit = ({ data, id } = {}) => {
  const config = {
    method: 'POST',
    url: 'reception/packet-category/update/' + id,
    data,
  };
  return HttpRequestHub(config);
};
